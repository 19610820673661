import { getImbalancesData } from "../../../../../../api/dashboard/imbalancesApi";
import { validateInput } from "../../helpers/tools/validateInput";
import { RotationsRenderClass } from "./rotationsRenderClass";
import { ZoneRenderClass } from "./zoneRenderClass";

export class ImbalancesOperationClass {
  constructor(tvChart, pair, imbalanceValue) {
    this.thisClassName = 'ImbalancesOperationClass'
    const additionalErrorMessage = 'constructor()'
    validateInput(tvChart, 'tvChart', this.thisClassName, additionalErrorMessage);
    validateInput(pair, 'pair', this.thisClassName, additionalErrorMessage);
    validateInput(imbalanceValue, 'imbalanceValue', this.thisClassName, additionalErrorMessage);

    this.tvChart = tvChart;
    this.pair = pair.replace("/", "");
    this.imbalanceValue = imbalanceValue;
  }

  plotZones = async () => {
    const zoneRender = new ZoneRenderClass(
      this.tvChart,
      this.pair,
      this.imbalanceValue
    );

    if (this.tvChart.current) {
      let zonesList = await getImbalancesData(
        `/json/imbalances/${this.pair.toLowerCase()}.json`
      );

      try {
        zoneRender.action(zonesList);
      } catch (error) {
        console.log(`draw_zones error on ${this.pair} => `, error);
      }
    }
  };

  plotRotations = async () => {
    const rotationsRender = new RotationsRenderClass(
      this.tvChart,
      this.pair,
      this.imbalanceValue
    );

    if (this.tvChart.current) {
      let rotationsList = await getImbalancesData(
        `/json/imbalances/${this.pair.toLowerCase()}Rotations.json`
      );
      try {
        rotationsRender.action(rotationsList);
      } catch (error) {
        console.log(`resultRotations error on ${this.pair} => `, error);
      }
    }
  };

  removeAllWidget = () => {
    if (this.tvChart.current && this.tvChart.current.activeChart()) {
      let allShape = this.tvChart.current.activeChart().getAllShapes();
      allShape.forEach((shape) => {
        this.tvChart.current.activeChart().removeEntity(shape.id);
      });
    }
  };
}
