import React from "react";

function ArrowTopSvg({ color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "14"}
      height={props.width || "8"}
      fill="none"
      viewBox={props.viewBox || "0 0 14 8"}
    >
      <path
        stroke={color || "#C2CFD6"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        d="M6 15L12 9L18 15"
      ></path>
    </svg>
  );
}

export default ArrowTopSvg;
