import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AccessLevelTable } from "../accessLevelTable";
import InputBase from "~/components/child/ui/InputBase";
import Button from "~/components/child/ui/Button";
import Modal from "~/components/child/ui/Modal";

import { role } from "../constants";

const CreateOrEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const roleId = searchParams.get("id");

  const [roleInfo, setRoleInfo] = useState({
    roleName: "",
    accessLevel: role.permissions,
  });
  const [error, setError] = useState("");
  const [confirm, setConfirm] = useState({ cancel: false, edit: false });

  useEffect(() => {
    if (roleId) {
      const roleDetails = role.singleSection.find(
        (item) => item.systemRole.id === roleId
      )?.systemRole;
      setRoleInfo({
        roleName: roleDetails?.roleName,
        accessLevel: roleDetails?.permissions,
      });
    }
  }, [roleId]);

  const validateRoleName = (name) => {
    const regex = /^[a-zA-Z\u0600-\u06FF\s]+$/;
    return regex.test(name);
  };

  const handleRoleNameChange = (e) => {
    const { value } = e.target;
    setRoleInfo({
      ...roleInfo,
      roleName: value,
    });
    if (validateRoleName(value)) {
      setError("");
    } else {
      setError("Please use Persian or English characters.");
    }
  };

  const allPermissionsUnchecked = roleInfo.accessLevel.every((product) =>
    product.actions.every((action) => !action.hasPermission)
  );

  console.log("allPermissionsUnchecked", allPermissionsUnchecked);

  return (
    <div className="main__router__container scrollbar-style scrollbar-style-thumb max-h-[92vh] overflow-y-auto">
      <div className="main__router__container__list-box-container">
        <div className="flex flex-col gap-6 bg-base p-8 rounded-lg">
          <h2 className="text-lg font-bold mb-4 text-sky-70 text-center">
            {roleId ? "Edit Role" : "New Role"}
          </h2>
          <div className="flex flex-col gap-1">
            <InputBase
              title="Role Name"
              placeholder="Enter Role Name"
              className={{
                input: `!text-left !w-[95%] !text-sm ${
                  error ? "!border-infrared-key" : ""
                }`,
                title: `text-sm  ${
                  error ? "!border-infrared-key" : "text-sky-60"
                } -top-[0.625rem]`,
              }}
              value={roleInfo.roleName}
              setValue={handleRoleNameChange}
              error={error}
            />
            <p className=" text-infrared-key text-xs font-roboto">{error && error}</p>
          </div>
          <h2 className="text-lg font-bold mb-4 text-sky-70">Set the access level</h2>
          <AccessLevelTable
            accessLevels={roleInfo.accessLevel}
            setAccessLevels={(value) =>
              setRoleInfo({ ...roleInfo, accessLevel: [...value] })
            }
          />
          <div className="flex justify-between gap-4">
            <Button
              onClick={() => {
                setConfirm({ ...confirm, cancel: true });
              }}
              className="w-full"
              variant="outline"
            >
              Cancel
            </Button>

            <Button
              className="w-full"
              variant="primary"
              disabled={!roleInfo.roleName || error.length > 0 || allPermissionsUnchecked}
              onClick={() => {
                if (roleId) setConfirm({ ...confirm, edit: true });
              }}
            >
              {roleId ? "Edit Role" : "Create Role"}
            </Button>
          </div>
        </div>
      </div>
      {confirm.cancel && (
        <Modal>
          <p className="text-lg font-roboto text-oil-dark">
            Are you sure you want to cancel this process?
          </p>

          <div className="flex items-center flex-row-reverse gap-4 justify-between">
            <Button
              onClick={() => navigate("/roles")}
              type="submit"
              variant="primary"
              className="w-full"
            >
              Yes , I’m sure
            </Button>

            <Button
              onClick={() => setConfirm({ ...confirm, cancel: false })}
              variant="outline"
              className="w-full"
            >
              No, back to page
            </Button>
          </div>
        </Modal>
      )}
      {confirm.edit && (
        <Modal>
          <p className="text-lg font-roboto text-oil-dark">
            Are you sure you want to edit this role?
          </p>

          <div className="flex items-center flex-row-reverse gap-4 justify-between">
            <Button type="submit" variant="primary" className="w-full">
              Yes , I’m sure
            </Button>

            <Button
              onClick={() => setConfirm({ ...confirm, edit: false })}
              variant="outline"
              className="w-full"
            >
              No, back to page
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default CreateOrEdit;
