import React, { useState } from "react";
import { MoveLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "~/components/child/ui/Button";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { role } from "../constants";
import { AccessLevelTable } from "~/components/pages/settings/roles/accessLevelTable";
import Modal from "~/components/child/ui/Modal";

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const roleDetails = role.singleSection.find(
    (item) => item.systemRole.id === id
  )?.systemRole;
  const [ConfirmDeletion, setConfirmDeletion] = useState(false);
  return (
    <div className="main__router__container scrollbar-style scrollbar-style-thumb max-h-[92vh] overflow-y-auto">
      <div className="main__router__container__list-box-container">
        <div className="flex flex-col gap-6 font-roboto">
          <button
            onClick={() => navigate("/roles")}
            className="text-base text-sky-key  flex items-center gap-2"
          >
            <MoveLeft color="#007bff" strokeWidth={1.5} />
            Back to previous page
          </button>
          <div className="flex items-center justify-between">
            <p className="text-xs  text-storm-60"> 2023/05/21- 14:25</p>
            <div className="flex items-center gap-4">
              <Button
                onClick={() => setConfirmDeletion(true)}
                className="w-fit px-4"
                variant="outline"
              >
                Delete Role
              </Button>
              <Button
                onClick={() => navigate(`/editRole?id=${id}`)}
                className="w-fit px-4"
                variant="primary"
              >
                Edit Role’s access level
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-between py-2.5 px-3.5 bg-base rounded-lg">
            <p className="text-base text-storm-80 font-robotoMed">
              {changeWordFormatToTitleCase(roleDetails?.roleName)}
            </p>
            <div className="flex items-center gap-1">
              <p className="flex items-center gap-1 text-base text-sky-70 ">
                <b className="font-robotoMed">By</b>
                {roleDetails?.createdBy}
              </p>
              -
              <p className="flex items-center gap-1 text-xs text-storm-60 ">
                {roleDetails?.createdAt}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-6 p-8 bg-base rounded-lg">
            <h3 className="text-lg font-robotoMed text-sky-70">Operators</h3>
            <div className="flex flex-wrap gap-2.5">
              {roleDetails?.operators?.map((item) => (
                <p className="border border-sky-key p-2 text-sm text-sky-key rounded-2xl bg-sky-10  ">
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-6 p-8 bg-base rounded-lg">
            <h3 className="text-lg font-robotoMed text-sky-70">Access level</h3>
            <AccessLevelTable accessLevels={roleDetails?.permissions} editable={false} />
          </div>
        </div>
      </div>
      {ConfirmDeletion && (
        <Modal className={{ container: "!gap-10" }}>
          <p className="text-lg font-roboto text-oil-dark">
            Are you sure you want to delete this role?
          </p>
          <div className="flex items-center flex-row-reverse gap-4 justify-between">
            <Button type="submit" variant="primary" className="w-full">
              Yes , I’m sure
            </Button>

            <Button
              onClick={() => setConfirmDeletion(false)}
              variant="outline"
              className="w-full"
            >
              No, back to page
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Details;
