export const role = {
  list: {
    data: [
      {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991c",
        name: "analyzer",
        type: "CUSTOM",
        kind: "SYSTEM",
        createdAt: "2024-11-16T05:52:59.086Z",
        updatedAt: "2024-10-22T05:52:59.086Z",
        createdBy: "0192b33a-d421-7872-ab82-6a911768941c",
        createdByName: "Masoume Mahmoudi",
        totalAssigned: "2",
      },
      {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991b",
        name: "super admin",
        type: "CUSTOM",
        kind: "SYSTEM",
        createdAt: "2024-11-16T05:52:59.086Z",
        updatedAt: "2024-10-22T05:52:59.086Z",
        createdBy: "0192b33a-d421-7872-ab82-6a911768941c",
        createdByName: "Masoume Mahmoudi",
        totalAssigned: "1",
      },
      {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991d",
        name: "fundamental analyzer",
        type: "CUSTOM",
        kind: "SYSTEM",
        createdAt: "2024-11-16T05:52:59.086Z",
        updatedAt: "2024-10-22T05:52:59.086Z",
        createdBy: "0192b33a-d421-7872-ab82-6a911768941c",
        createdByName: "Masoume Mahmoudi",
        totalAssigned: "3",
      },
      {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991e",
        name: "Trader",
        type: "CUSTOM",
        kind: "SYSTEM",
        createdAt: "2024-11-16T05:52:59.086Z",
        updatedAt: "2024-10-22T05:52:59.086Z",
        createdBy: "0192b33a-d421-7872-ab82-6a911768941c",
        createdByName: "Masoume Mahmoudi",
        totalAssigned: "7",
      },
      {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991f",
        name: "Trader level 2",
        type: "CUSTOM",
        kind: "SYSTEM",
        createdAt: "2024-11-16T05:52:59.086Z",
        updatedAt: "2024-10-22T05:52:59.086Z",
        createdBy: "0192b33a-d421-7872-ab82-6a911768941c",
        createdByName: "Masoume Mahmoudi",
        totalAssigned: "8",
      },
      {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991g",
        name: "Trader level 1",
        type: "CUSTOM",
        kind: "SYSTEM",
        createdAt: "2024-11-16T05:52:59.086Z",
        updatedAt: "2024-10-22T05:52:59.086Z",
        createdBy: "0192b33a-d421-7872-ab82-6a911768941c",
        createdByName: "Masoume Mahmoudi",
        totalAssigned: "1",
      },
    ],
    timestamp: "2024-11-13T08:19:01.286Z",
    totalCount: 20,
    page: 1,
    pageSize: 6,
    totalPages: 4,
  },
  permissions: [
    {
      product: "Vault",
      name: "Vault",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Vault information",
      name: "Vault information",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Profit & loss",
      name: "Profit & loss",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "aum-nav-share price charts",
      name: "aum-nav-share price charts",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Portfolio",
      name: "Portfolio",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Depositors",
      name: "Depositors",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Trades",
      name: "Trades",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Deposits & Withdraw",
      name: "Deposits & Withdraw",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Fees",
      name: "Fees",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Referrals",
      name: "Referrals",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Contracts",
      name: "Contracts",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
    {
      product: "Treasury",
      name: "Treasury",
      actions: [
        {
          id: "",
          type: "CREATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "UPDATE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "DELETE",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ",
          hasPermission: false,
        },
        {
          id: "uuid",
          type: "READ ALL",
          hasPermission: false,
        },
      ],
    },
  ],
  singleSection: [
    {
      systemRole: {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991c",
        roleName: "Analyzer",
        createdBy: "Zohre Mohajeri",
        createdAt: "2023/05/21- 14:25",
        operators: ["Zohre Mohajeri", "Masoume Mahmoudi", "Keyvan Rasouli"],
        permissions: [
          {
            product: "Vault",
            name: "Vault",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Vault information",
            name: "Vault information",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Profit & loss",
            name: "Profit & loss",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "aum-nav-share price charts",
            name: "aum-nav-share price charts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Portfolio",
            name: "Portfolio",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Depositors",
            name: "Depositors",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Trades",
            name: "Trades",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Deposits & Withdraw",
            name: "Deposits & Withdraw",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Fees",
            name: "Fees",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Referrals",
            name: "Referrals",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Contracts",
            name: "Contracts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Treasury",
            name: "Treasury",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
        ],
      },
    },
    {
      systemRole: {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991b",
        roleName: "super admin",
        createdBy: "Masoume Mahmoudi",
        createdAt: "2023/05/19- 12:00",
        operators: ["Zohre Mohajeri", "Masoume Mahmoudi", "Keyvan Rasouli", "Atena Abed"],

        permissions: [
          {
            product: "Vault",
            name: "Vault",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Vault information",
            name: "Vault information",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Profit & loss",
            name: "Profit & loss",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "aum-nav-share price charts",
            name: "aum-nav-share price charts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Portfolio",
            name: "Portfolio",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Depositors",
            name: "Depositors",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Trades",
            name: "Trades",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Deposits & Withdraw",
            name: "Deposits & Withdraw",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Fees",
            name: "Fees",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Referrals",
            name: "Referrals",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Contracts",
            name: "Contracts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
          {
            product: "Treasury",
            name: "Treasury",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: true,
              },
            ],
          },
        ],
      },
    },
    {
      systemRole: {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991d",
        roleName: "fundamental analyzer",
        createdBy: "Keyvan Rasouli",
        createdAt: "2023/05/20- 10:00",
        operators: ["Keyvan Rasouli", "Atena Abed"],

        permissions: [
          {
            product: "Vault",
            name: "Vault",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Vault information",
            name: "Vault information",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Profit & loss",
            name: "Profit & loss",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "aum-nav-share price charts",
            name: "aum-nav-share price charts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Portfolio",
            name: "Portfolio",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Depositors",
            name: "Depositors",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Trades",
            name: "Trades",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Deposits & Withdraw",
            name: "Deposits & Withdraw",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Fees",
            name: "Fees",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Referrals",
            name: "Referrals",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Contracts",
            name: "Contracts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Treasury",
            name: "Treasury",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
        ],
      },
    },
    {
      systemRole: {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991e",
        roleName: "Trader",
        createdBy: "Keyvan Rasouli",
        createdAt: "2023/05/20- 10:00",
        operators: ["Keyvan Rasouli", "Atena Abed"],

        permissions: [
          {
            product: "Vault",
            name: "Vault",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Vault information",
            name: "Vault information",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Profit & loss",
            name: "Profit & loss",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "aum-nav-share price charts",
            name: "aum-nav-share price charts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Portfolio",
            name: "Portfolio",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Depositors",
            name: "Depositors",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Trades",
            name: "Trades",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Deposits & Withdraw",
            name: "Deposits & Withdraw",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Fees",
            name: "Fees",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Referrals",
            name: "Referrals",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Contracts",
            name: "Contracts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Treasury",
            name: "Treasury",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
        ],
      },
    },
    {
      systemRole: {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991f",
        roleName: "Trader level 2",
        createdBy: "Keyvan Rasouli",
        createdAt: "2023/05/20- 10:00",
        operators: ["Keyvan Rasouli", "Atena Abed"],

        permissions: [
          {
            product: "Vault",
            name: "Vault",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Vault information",
            name: "Vault information",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Profit & loss",
            name: "Profit & loss",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "aum-nav-share price charts",
            name: "aum-nav-share price charts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Portfolio",
            name: "Portfolio",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Depositors",
            name: "Depositors",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Trades",
            name: "Trades",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Deposits & Withdraw",
            name: "Deposits & Withdraw",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Fees",
            name: "Fees",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Referrals",
            name: "Referrals",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Contracts",
            name: "Contracts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Treasury",
            name: "Treasury",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
        ],
      },
    },
    {
      systemRole: {
        id: "0192b2c9-0ed8-7da6-9ed2-5c80baef991g",
        roleName: "Trader level 1",
        createdBy: "Keyvan Rasouli",
        createdAt: "2023/05/20- 10:00",
        operators: ["Keyvan Rasouli", "Atena Abed"],

        permissions: [
          {
            product: "Vault",
            name: "Vault",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Vault information",
            name: "Vault information",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Profit & loss",
            name: "Profit & loss",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "aum-nav-share price charts",
            name: "aum-nav-share price charts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Portfolio",
            name: "Portfolio",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Depositors",
            name: "Depositors",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Trades",
            name: "Trades",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: true,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: true,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Deposits & Withdraw",
            name: "Deposits & Withdraw",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Fees",
            name: "Fees",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Referrals",
            name: "Referrals",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Contracts",
            name: "Contracts",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
          {
            product: "Treasury",
            name: "Treasury",
            actions: [
              {
                id: "",
                type: "CREATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "UPDATE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "DELETE",
                hasPermission: false,
              },
              {
                id: "uuid",
                type: "READ",
                hasPermission: false,
              },
              {
                id: "",
                type: "READ ALL",
                hasPermission: false,
              },
            ],
          },
        ],
      },
    },
  ],
};
