import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./components/child/UserContext";
import {MainChartProvider} from "~/components/child/tradingViewMainChart/context/MainChartContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
      <MainChartProvider>
          <App/>
      </MainChartProvider>
  </UserProvider>
);
reportWebVitals();
