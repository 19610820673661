import React from "react";

function StarSvg({ isFilled = false, ...props }) {
  return (
    <svg
      width={props.width || "25"}
      height={props.height || "24"}
      viewBox="0 0 25 24"
      fill={isFilled ? props.fill || "#007BFF" : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1208 4.49639C11.6398 3.28283 13.3602 3.28283 13.8792 4.49639L14.8011 6.65209C15.1627 7.49758 15.9592 8.07631 16.8751 8.15893L19.2102 8.36959C20.5247 8.48818 21.0563 10.1243 20.0625 10.9929L18.2972 12.5359C17.6049 13.141 17.3006 14.0774 17.505 14.974L18.0263 17.2599C18.3197 18.5467 16.9279 19.5579 15.7947 18.8812L13.7818 17.6791C12.9923 17.2076 12.0077 17.2076 11.2182 17.6791L9.20526 18.8812C8.07206 19.5579 6.68029 18.5467 6.97372 17.2599L7.49495 14.974C7.69939 14.0774 7.39513 13.141 6.70276 12.5359L4.93746 10.9929C3.94368 10.1243 4.47529 8.48818 5.78983 8.36959L8.12492 8.15893C9.04076 8.07631 9.83731 7.49758 10.1989 6.65209L11.1208 4.49639Z"
        stroke={!isFilled ? props.stroke || "#007BFF" : "none"}
      />
    </svg>
  );
}

export default StarSvg;
