import React, { useMemo, useEffect, useState, useCallback, useContext } from "react";
import { UserContext } from "~/components/child/UserContext";
import Dropdown from "~/components/child/ui/DropDown";
import Description from "~/components/child/ui/Description";
import Modal from "~/components/child/ui/Modal";
import { PairsSymbol } from "~/components/child/Symbols";
import {
  ReceiveNotification,
  NotificationModal,
} from "~/components/child/notification/ReceiveNotification";
import { PointAmount, DeletePointConfirm } from "./PointAmount";
import { usePairPrice } from "~/utils/hooks";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";
import { calculateTotalAverage, riskToRewardResult } from "~/utils/riskToRewardResult";
import { validatePriceConditions } from "~/utils/validatePriceConditions";

import { sortDescending, sortAscending } from "../../helper";
import { calculateRiskParams, calculateSumWeight, sumWeightValidation } from "./helper";

const RiskParams = ({ label, supportingText, value }) => (
  <div className="text-oil-60 flex items-center w-full gap-4 font-roboto">
    <p className="text-storm-80 flex items-center gap-1 min-w-fit font-roboto text-sm">
      {label}
      {supportingText && (
        <span className="text-xs flex text-oil-60 ">({supportingText})</span>
      )}
    </p>
    <hr className="w-full border-black opacity-10 " />
    <span className="text-xs">{value}</span>
  </div>
);

export const Form = ({
  setupValues,
  setSetupValues,
  heatmap,
  setErrorMessage,
  errorMessage,
  sumWeight,
  setSumWeight,
  vaultsList,
  isEditMode,
  reviewChangesInData,
}) => {
  const { user } = useContext(UserContext);
  const [switchIsOn, setSwitchIsOn] = useState(false);
  const [showModal, setShowModal] = useState({
    notification: false,
    deletedPoint: undefined,
  });

  const pairPrice = usePairPrice(heatmap?.details?.pair?.pair);

  const pointAmountProps = useMemo(
    () => ({
      selectedPoints: setupValues?.points,
      setSetupValues: setSetupValues,
      onDeletePoint: (value) => setShowModal({ ...showModal, deletedPoint: value }),
      isEditMode: isEditMode,
      reviewChangesInData: reviewChangesInData,
    }),
    [setupValues?.points, showModal.deletedPoint, isEditMode]
  );

  useEffect(() => {
    if (setupValues.usersId.length > 0 && !switchIsOn) setSwitchIsOn(true);
  }, [setupValues.usersId]);

  useEffect(() => {
    if (setupValues?.points?.ep?.length > 0 && setupValues?.points?.sl?.length > 0)
      setSetupValues((prevState) => ({
        ...prevState,
        points: {
          ...prevState.points,
          tp: prevState?.points?.tp?.map((item) => ({
            ...item,
            rr: calculateRiskToReward(item.value),
          })),
        },
      }));
  }, [setupValues?.points?.ep, setupValues?.points?.sl]);

  useEffect(() => {
    const { mdd, maxProfit, rr } = calculateRiskParams(
      setupValues?.points?.ep,
      setupValues?.points?.sl,
      setupValues?.points?.tp,
      heatmap?.details?.technical.type[0].toLowerCase()
    );
    setSetupValues((prevState) => ({
      ...prevState,
      maxDrawDown: mdd || 0,
      maxProfit: maxProfit || 0,
      RR: rr || 0,
    }));
    setSumWeight({
      ep: calculateSumWeight(setupValues?.points?.ep),
      tp: calculateSumWeight(setupValues?.points?.tp),
      sl: calculateSumWeight(setupValues?.points?.sl),
    });

    setErrorMessage({ ...errorMessage, create: undefined });
  }, [setupValues.points]);

  useEffect(() => {
    setErrorMessage({
      ...errorMessage,
      pointsWeight: {
        ep: sumWeightValidation(sumWeight?.ep),
        tp: sumWeightValidation(sumWeight?.tp),
        sl: sumWeightValidation(sumWeight?.sl),
      },
    });
  }, [sumWeight]);

  useEffect(() => {
    const pointErrors = validatePriceConditions(
      heatmap?.details?.technical?.type[0]?.toLowerCase(),
      setupValues.points
    );
    if (Object.values(pointErrors).length > 0)
      setErrorMessage({ points: { ...pointErrors } });
    else setErrorMessage({ ...errorMessage, points: undefined });
  }, [setupValues?.points?.ep, setupValues?.points?.sl, setupValues?.points?.tp]);

  const sortedEpList =
    heatmap?.details?.technical.type[0] === "LONG"
      ? sortDescending(heatmap?.details?.technical?.ep, "base_amount")
      : sortAscending(heatmap?.details?.technical?.ep, "base_amount");

  const sortedSlList =
    heatmap?.details?.technical.type[0] === "LONG"
      ? sortDescending(heatmap?.details?.technical?.sl, "base_amount")
      : sortAscending(heatmap?.details?.technical?.sl, "base_amount");

  const sortedTpList =
    heatmap?.details?.technical.type[0] === "LONG"
      ? sortAscending(heatmap?.details?.technical?.tp, "base_amount")
      : sortDescending(heatmap?.details?.technical?.tp, "base_amount");

  const calculateRiskToReward = useCallback(
    (value) => {
      if (setupValues?.points?.ep?.length > 0 && setupValues?.points?.sl?.length > 0) {
        return riskToRewardResult(
          calculateTotalAverage(setupValues?.points?.ep),
          calculateTotalAverage(setupValues?.points?.sl),
          value
        );
      }
      return null;
    },
    [setupValues?.points?.ep, setupValues?.points?.sl]
  );

  const positionType = setupValues.positionType || heatmap?.details?.technical.type[0];

  useEffect(() => {
    setSetupValues((prevState) => ({
      ...prevState,
      usersId: switchIsOn
        ? [
            { key: user.uuid, value: user.first_name + user.last_name },
            ...prevState.usersId,
          ]
        : [],
    }));
  }, [switchIsOn]);

  return (
    <>
      <div className="flex p-8 flex-col bg-base rounded-3xl">
        <div className="flex flex-col gap-6 bg-storm-10 rounded-lg py-4 px-2.5">
          <h3 className="text-lg font-robotoMed text-sky-70">New Setup</h3>

          <div className="flex items-center justify-between">
            <PairsSymbol
              className="text-sm font-robotoMed"
              image={heatmap?.details?.pair?.image}
              pair={heatmap?.details?.pair?.pair}
            />
            <span
              className={`py-1.5 px-2.5 rounded-2xl text-sm font-normal font-roboto ${
                positionType.startsWith("L")
                  ? "bg-twilight-30 text-twilight-70"
                  : "text-infrared-40 bg-infrared-10"
              }  `}
            >
              {changeWordFormatToTitleCase(positionType)}
            </span>
          </div>

          <div className="flex items-start w-full justify-between gap-2">
            <Dropdown
              bgColor="base"
              value={{
                value: setupValues?.vault?.value,
              }}
              inputStartAdornment={
                <span className="flex text-oil-60 font-robotoMed pl-2 py-6">
                  Select Vault
                </span>
              }
              optionsList={vaultsList?.map((item) => ({
                key: item.uuid,
                value: item.name,
              }))}
              renderItem={(item) => (
                <span className="py-2 text-xs font-roboto text-left">{item?.value}</span>
              )}
              onSelect={(item) => {
                setSetupValues((prevState) => ({ ...prevState, vault: item }));
                if (reviewChangesInData) reviewChangesInData();
              }}
            />
            <ReceiveNotification
              users={setupValues?.usersId.filter((item) => item.key !== user.uuid)}
              onClickHandler={() =>
                setShowModal({
                  ...showModal,
                  notification: true,
                })
              }
              switchIsOn={switchIsOn}
              setSwitchIsOn={(value) => {
                setSwitchIsOn(value);
                if (reviewChangesInData) reviewChangesInData();
              }}
            />
          </div>
          <div className="flex items-center gap-8">
            <RiskParams label="Max loss" value={`${setupValues.maxDrawDown}%`} />

            <RiskParams label="Max Profit" value={`${setupValues.maxProfit}%`} />
            <RiskParams label="R:R" value={setupValues.RR} />
          </div>
          <div className="flex items-start gap-4">
            <PointAmount
              label="ep"
              labelColor="sky-key"
              points={sortedEpList}
              sortFunction={(pointValue, points) => {
                if (pairPrice > pointValue) return sortDescending([...points]);
                else return sortAscending([...points]);
              }}
              {...pointAmountProps}
            />
            <PointAmount
              label="tp"
              labelColor="leaf-key"
              points={sortedTpList}
              sortFunction={(pointValue, points) =>
                heatmap?.details?.technical.type[0] === "LONG"
                  ? sortAscending([...points])
                  : sortDescending([...points])
              }
              calculateRiskToReward={calculateRiskToReward}
              {...pointAmountProps}
            />
            <PointAmount
              label="sl"
              labelColor="infrared-key"
              points={sortedSlList}
              sortFunction={(pointValue, points) =>
                heatmap?.details?.technical.type[0] === "LONG"
                  ? sortDescending([...points])
                  : sortAscending([...points])
              }
              {...pointAmountProps}
            />
          </div>
          <div className="flex flex-col gap-1">
            {errorMessage?.pointsWeight &&
              Object.keys(errorMessage?.pointsWeight)
                .filter((key) => errorMessage?.pointsWeight[key])
                ?.map((key) => (
                  <p className="font-roboto text-sm text-infrared-key">
                    {`The total weight of ${key.toUpperCase()}s must be 100%`}
                  </p>
                ))}
            {errorMessage &&
              errorMessage?.points &&
              Object.values(errorMessage?.points)?.map((error, index) => {
                if (error.length > 0)
                  return (
                    <p
                      key={index}
                      className=" font-roboto text-sm text-infrared-key overflow-auto"
                    >
                      {error}
                    </p>
                  );
              })}
          </div>
        </div>
        <Description
          description={setupValues.description}
          setDescription={(value) => {
            setSetupValues((prevState) => ({
              ...prevState,
              description: value,
            }));
            if (reviewChangesInData) reviewChangesInData();
          }}
          limitNumber={7000}
        />
      </div>

      {showModal.deletedPoint && (
        <Modal>
          <DeletePointConfirm
            pointInfo={{ ...showModal.deletedPoint }}
            confirmHandler={() => {
              setSetupValues((prevState) => ({
                ...prevState,
                points: {
                  ...prevState.points,
                  [showModal.deletedPoint.label]: prevState.points[
                    showModal.deletedPoint.label
                  ].filter((item) => item.id !== showModal.deletedPoint.id),
                },
              }));
              setShowModal({
                ...showModal,
                deletedPoint: undefined,
              });
              if (reviewChangesInData) reviewChangesInData();
            }}
            cancelHandler={() => {
              setShowModal({
                ...showModal,
                deletedPoint: undefined,
              });
            }}
          />
        </Modal>
      )}
      {showModal.notification && (
        <Modal className={{ container: "w-2/5 min-h-[31.25rem]" }}>
          <NotificationModal
            selectedUsers={[...setupValues?.usersId]}
            setShowNotificationModal={(value) =>
              setShowModal({ ...showModal, notification: value })
            }
            onSelectUser={(item) => {
              setSetupValues({
                ...setupValues,
                usersId: onChangeCheckboxes(setupValues.usersId, {
                  key: item.key,
                  value: item.value,
                }),
              });
              if (reviewChangesInData) reviewChangesInData();
            }}
          />
        </Modal>
      )}
    </>
  );
};
