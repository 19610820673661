import { callRestGet, callRestPut } from "../../utils/callRestApi";
import { createEndpointQueryParams } from "../../utils/createEndpointQueryParams";

export const tokensApi = {
  list: async (data) => {
    const result = { data: null, error: null };

    await callRestGet(`/dashboard/tokens-list?${createEndpointQueryParams(data)}`, null)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
  favorite: async (id, data) => {
    const result = { data: null, error: null };

    await callRestPut(`dashboard/tokens-list/favorite/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          result.data = res.data;
        } else {
          result.error = res;
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.setItem("accessToken", "");
          window.location.href = "/login";
        }
        result.error = err;
      });

    return result;
  },
};
