import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination/lib/Pagination";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import MoreOneSvg from "~/assets/icon/svgJsx/MoreOneSvg";

import { Loading } from "~/components/child/ui/Loading";
import Button from "~/components/child/ui/Button";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

import { role } from "../constants";

const List = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [listItems, setListItems] = useState();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (role.list) setListItems({ ...role.list });
    setIsLoading(false);
  }, []);

  return (
    <div className="main__router__container">
      <div className="main__router__container__list-box-container flex flex-col gap-4">
        <div className="flex items-end justify-end">
          <Button
            onClick={() => navigate("/newRole")}
            className="w-fit px-4"
            variant="primary"
          >
            New Role
          </Button>
        </div>

        {!isLoading && listItems?.data?.length > 0 ? (
          <div>
            <div className="list-table-cstm-one scrollbar-style scrollbar-style-thumb">
              <table className="list-table-cstm-one__content-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Role name</th>
                    <th>Operator(s)</th>
                    <th>Creator</th>
                    <th>Creation Date </th>
                    <th>Detail</th>
                    <th>...</th>
                  </tr>
                </thead>

                <tbody>
                  {listItems?.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td className="list-table-cstm-one__content-table__title">
                        {changeWordFormatToTitleCase(item.name)}
                      </td>

                      <td className="list-table-cstm-one__content-table__flex">
                        {item.totalAssigned}
                      </td>

                      <td>
                        {" "}
                        {changeWordFormatToTitleCase(item?.createdByName?.split(" ")[0]) +
                          " " +
                          changeWordFormatToTitleCase(item?.createdByName?.split(" ")[1])}
                      </td>
                      <td>{item?.createdAt?.split("T")[0]}</td>

                      <td className="list-table-cstm-one__content-table__details-arrow">
                        <div className="flex justify-center items-center">
                          <NavLink
                            className="flex justify-center items-center"
                            target="_blank"
                            to={`/roles/${item?.id}`}
                          >
                            <ArrowRightSvg />
                          </NavLink>
                        </div>
                      </td>
                      <td className="list-table-cstm-one__content-table__flex">
                        <MoreOneSvg />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination-one" role="navigation">
              <Pagination
                current={currentPage}
                total={6}
                pageSize={listItems?.pageSize}
                onChange={handlePageChange}
                showSizeChanger={false}
                prevIcon={<ArrowLeftSvg />}
                nextIcon={<ArrowRightSvg />}
                data-testid="arrow-right"
              />
            </div>
          </div>
        ) : !isLoading ? (
          <div className="empty-heatMap">
            <h4 className="empty-heatMap__title">No Result!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default List;
