import React from "react";

function SearchSvg({ color, className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0003 18C11.8511 17.9999 13.6447 17.3581 15.0754 16.1839C16.506 15.0097 17.4853 13.3757 17.8464 11.5605C18.2074 9.74517 17.9278 7.86087 17.0552 6.2286C16.1827 4.59633 14.7712 3.3171 13.0613 2.60887C11.3513 1.90064 9.4486 1.80723 7.67747 2.34455C5.90635 2.88187 4.37635 4.01668 3.34813 5.55563C2.3199 7.09457 1.85709 8.94243 2.03856 10.7844C2.22002 12.6263 3.03453 14.3483 4.3433 15.657C5.0862 16.3999 5.96813 16.9891 6.93876 17.3911C7.90939 17.7931 8.9497 18 10.0003 18Z"
        stroke={color || "#525252"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0005 22L18.0005 18"
        stroke={color || "#525252"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchSvg;
