import { baseChartConfig } from "../../config/createChartConfig";
import { validateInput } from "../helpers/tools/validateInput";

export function createPairDataConfig(baseSymbol, quoteSymbol, exchange) {
  let result = { pair: "null", dataFeedUrl: null };
  if (baseChartConfig) {
    switch (exchange) {
      case "binance":
        return (result = {
          pair: `BINANCE:${baseSymbol}/${quoteSymbol}`,
          dataFeed: baseChartConfig?.datafeedUrl,
        });
      case "okx":
        return (result = {
          pair: `OKX:${baseSymbol}/${quoteSymbol}`,
          dataFeed: baseChartConfig?.datafeedUrl,
        });
      case "mexc":
        return (result = {
          pair: `MEXC:${baseSymbol}/${quoteSymbol}`,
          dataFeed: baseChartConfig?.datafeedUrl,
        });
      default:
        validateInput(null, 'exchange', 'createPairDataConfig', `Invalid exchange: ['${exchange}']. Valid exchange are:['binance' , 'okx']`)
    }
  }
  return result;
}
