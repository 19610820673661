import React from "react";

function ArrowRightSvg({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      fill="none"
      viewBox="0 0 8 14"
      className={className}
    >
      <path
        stroke="#007BFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 13l6-6-6-6"
      ></path>
    </svg>
  );
}

export default ArrowRightSvg;
