import {
    convertChartTypeCodeToName
} from "~/components/child/tradingViewMainChart/utils/converters/convertChartTypeCodeToName";
import {validateInput} from "~/components/child/tradingViewMainChart/utils/helpers/tools/validateInput";

export const createChartfavorites = (favorites) => {
    validateInput(favorites, 'favorites', 'createChartfavorites')
    return {
        intervals: favorites[0]?.intervals ?? [],
        indicators: favorites[0]?.indicators ?? [],
        drawingTools: favorites[0]?.drawingTools ?? [],
        chartTypes: convertChartTypeCodeToName(favorites[0]?.chartTypes) ?? [],
    }
}


export const createChartFavoritesSettingsAdapter = (favorites, setFavorites) => {
    const functionName = createChartFavoritesSettingsAdapter.name
    validateInput(favorites, 'favorites', functionName)
    validateInput(setFavorites, 'setFavorites', functionName)
    return {

        initialSettings: {
            "loadChartDialog.favorites": favorites[1]?.loadChartDialog ?? [],
        },

        setValue: function (key, value) {

            setFavorites((prevFavorites) => {
                const updatedFavorites = [...prevFavorites];

                const updateFavoriteKey = (subKey, parsedValue) => {
                    updatedFavorites[0] = {
                        ...updatedFavorites[0],
                        [subKey]: parsedValue,
                    };
                };

                const keyMapping = {
                    "chart.favoriteDrawings": () =>
                        updateFavoriteKey("drawingTools", JSON.parse(value)),
                    "chart.favoriteLibraryIndicators": () =>
                        updateFavoriteKey("indicators", JSON.parse(value)),
                    "IntervalWidget.quicks": () =>
                        updateFavoriteKey("intervals", JSON.parse(value)),
                    "StyleWidget.quicks": () =>
                        updateFavoriteKey("chartTypes", JSON.parse(value)),
                    "loadChartDialog.favorites": () => {
                        updatedFavorites[1] = {
                            ...updatedFavorites[1],
                            loadChartDialog: value,
                        };
                    },
                };

                if (keyMapping[key]) {
                    keyMapping[key]();
                    // checkChartHasChanged && checkChartHasChanged();
                }

                return updatedFavorites;
            });
        },

        removeValue: function (key) {
            console.log(`remove value: ${key}`);
        },
    };
};