import React, { useState, useEffect, useRef } from "react";
import { getTechnicalStatusTheme } from "~/utils/getTechnicalStatusTheme";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

import ArrowDownSvg from "~/assets/icon/svgJsx/ArrowDownSvg";

export const Status = ({ currentStatus, setShowConfirmModal, isEditable = true }) => {
  const submenuRef = useRef(null);
  const buttonRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  if (currentStatus)
    return (
      <div className="text-storm-key font-roboto text-xs flex items-center justify-center  relative gap-1.5 mr-3">
        Status:
        {showTooltip && (
          <div
            ref={submenuRef}
            className=" absolute w-24 top-[80%] right-0 bg-white p-2 z-10 shadow-[0_1px_8px_0_rgba(0,0,0,.122)]  text-sm font-roboto flex flex-col gap-2 py-2 px-3 bg-storm-light rounded-lg"
          >
            <p className="text-sky-key">Change to:</p>
            <button
              type="button"
              className="text-oil-50 flex text-left w-full leading-6"
              onClick={setShowConfirmModal}
            >
              Close
            </button>
          </div>
        )}
        <button
          className={`border flex items-center gap-1 py-[0.3125rem] px-4 text-sm rounded-3xl h-auto  ${getTechnicalStatusTheme(
            currentStatus?.toUpperCase()
          )}`}
          disabled={
            !isEditable ||
            (currentStatus !== "no_touch" &&
              currentStatus !== "ongoing" &&
              currentStatus !== "No Touch")
          }
          ref={buttonRef}
          onClick={() => setShowTooltip(!showTooltip)}
        >
          {changeWordFormatToTitleCase(currentStatus)}
          {isEditable &&
            (currentStatus === "no_touch" ||
              currentStatus === "ongoing" ||
              currentStatus === "No Touch") && (
              <ArrowDownSvg
                width={12}
                height={12}
                viewBox="0 0 20 20"
                className=" fill-storm-40"
                color="#9AB0BC"
              />
            )}
        </button>
      </div>
    );
};
