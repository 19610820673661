import React, {createContext, useState, useEffect, useRef} from "react";
import useLocalStorage from "use-local-storage";
import { mainChartApi } from "~/api/dashboard/mainChartApi";
import { nearRealTimeDataUpdateHelper } from "~/components/child/tradingViewMainChart/utils/helpers/api/nearRealTimeDataUpdateHelper";

export const MainChartContext = createContext();

export const MainChartProvider = ({ children }) => {
    const accessToken = localStorage.getItem("accessToken");

    const currentFavoriteData = useRef(null);
    const beforeFavoriteData = useRef(null);
    const hasInitialized = useRef(false)

    const [isLoading, setIsLoading] = useState(true);
    const [favorites, setFavorites] = useLocalStorage('full_chart_favorites', null);
    const [localState, setLocalState] = useLocalStorage('general_full_chart_data', {
        chartData: null,
        theme: null,
        layoutList: null,
        templateList: null,
        nearRealTimeData: null,
    });


    const isChartDataValid = !!(
        !isLoading &&
        localState?.chartData &&
        localState?.theme &&
        favorites &&
        localState?.layoutList &&
        localState?.templateList &&
        localState?.nearRealTimeData &&
        localState?.nearRealTimeData?.chartData &&
        localState?.nearRealTimeData.chartTheme &&
        localState?.nearRealTimeData.favorites
    );

    const isNearRealTimeDataValid = !!(
        localState?.chartData &&
        localState?.theme &&
        favorites &&
        localState?.nearRealTimeData?.chartData &&
        localState?.nearRealTimeData?.chartTheme &&
        localState?.nearRealTimeData?.favorites
    );

    const updateChartData = (data) => {
        setLocalState(prevState => ({
            ...prevState,
            chartData: data
        }));
    };

    const updateTheme = (theme) => {
        setLocalState(prevState => ({
            ...prevState,
            theme: theme
        }));
    };

    const updateFavorites = (favorites) => {
        setFavorites(favorites)
    };

    const updateLayoutList = (layouts) => {
        setLocalState(prevState => ({
            ...prevState,
            layoutList: layouts
        }));
    };

    const updateTemplateList = (templates) => {
        setLocalState(prevState => ({
            ...prevState,
            templateList: templates
        }));
    };

    const updateNearRealTimeData = (data) => {
        setLocalState(prevState => ({
            ...prevState,
            nearRealTimeData: data
        }));
    };

    const fetchMainChartDetails = async () => {

        try {
            const getMainChartDetail = await mainChartApi.detail("main_chart_data");
            const getLayoutList = await mainChartApi.detail("layouts");
            const getTemplateList = await mainChartApi.detail("template");

            if (getMainChartDetail?.data && getLayoutList?.data && getTemplateList?.data) {
                const fullChartData = JSON.parse(getMainChartDetail?.data?.chart_data?.chart_data);
                const layoutListData = JSON.parse(getLayoutList?.data?.chart_data?.chart_data);
                const templateListData = JSON.parse(getTemplateList?.data?.chart_data?.chart_data);

                setLocalState({
                    chartData: fullChartData?.chartData,
                    theme: fullChartData?.chartTheme,
                    layoutList: layoutListData,
                    templateList: templateListData,
                    nearRealTimeData: {
                        chartData: fullChartData?.chartData,
                        chartTheme: fullChartData?.chartTheme,
                        favorites: fullChartData?.favorites,
                    },
                })
                setFavorites(fullChartData?.favorites)
                setIsLoading(false);

            } else {
                setIsLoading(false);
                setLocalState({
                    chartData: null,
                    theme: null,
                    layoutList: null,
                    templateList: null,
                    nearRealTimeData: null
                })
                setFavorites(null)
            }
        } catch (error) {
            console.error("Error fetching main chart data details: =>", error);
            setIsLoading(false);
            setLocalState({
                chartData: null,
                theme: null,
                layoutList: null,
                templateList: null,
                nearRealTimeData: null
            })
            setFavorites(null)
        }
    };

    const hasUnsavedChanges = () => {
        return currentFavoriteData.current &&
            beforeFavoriteData.current &&
            beforeFavoriteData.current !== currentFavoriteData.current;
    }

    const hasFavoriteChartDataChanged = (hasFullChartDataSavedChanges) => {
        if (hasFullChartDataSavedChanges) {
            beforeFavoriteData.current = currentFavoriteData.current;
        } else {
            if (isNearRealTimeDataValid && hasUnsavedChanges()) {
                const newNearRealTimeData = {
                    ...localState?.nearRealTimeData,
                    favorites: JSON.parse(currentFavoriteData.current)
                }
                beforeFavoriteData.current = currentFavoriteData.current;
                nearRealTimeDataUpdateHelper(newNearRealTimeData);
            }
        }
    }


    useEffect(() => {
        if (accessToken && accessToken.length > 0) fetchMainChartDetails();
    }, [accessToken]);

    useEffect(() => {
        updateNearRealTimeData({
            chartData: localState?.chartData,
            chartTheme: localState?.theme,
            favorites: favorites,
        });
    }, [localState?.chartData, localState?.theme, favorites]);

    useEffect(() => {
        if (!hasInitialized.current && favorites) {
            const stringifyFavorites = JSON.stringify(favorites);
            currentFavoriteData.current = stringifyFavorites;
            beforeFavoriteData.current = stringifyFavorites;
            hasInitialized.current = true;
        }
    }, [favorites, localState?.nearRealTimeData?.favorites]);

    useEffect(() => {
        if (hasInitialized.current && favorites) {
            currentFavoriteData.current = JSON.stringify(favorites);
        }
    }, [favorites, localState?.nearRealTimeData?.favorites]);

    useEffect(() => {
        const beforeUnloadEvent = (event) => {
            if (hasUnsavedChanges()) {
                event.preventDefault();
                event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
                hasFavoriteChartDataChanged();
            }
        };

        if (accessToken && accessToken.length > 0) {
            window.addEventListener("beforeunload", beforeUnloadEvent);
        }

        return () => {
            window.removeEventListener("beforeunload", beforeUnloadEvent);
        };
    }, [accessToken]);


    return (
        <MainChartContext.Provider
            value={{
                isLoading,
                chartData: localState?.chartData,
                setChartData: updateChartData,
                theme: localState?.theme,
                setTheme: updateTheme,
                favorites: favorites,
                setFavorites: updateFavorites,
                layoutList: localState?.layoutList,
                setLayoutList: updateLayoutList,
                templateList: localState?.templateList,
                setTemplateList: updateTemplateList,
                nearRealTimeData: localState?.nearRealTimeData,
                setNearRealTimeData: updateNearRealTimeData,
                isChartDataValid,
                hasFavoriteChartDataChanged
            }}
        >
            {children}
        </MainChartContext.Provider>
    );
};
