import React, { useState } from "react";
import ArrowDownSvg from "../../../assets/icon/svgJsx/ArrowDownSvg";
import ArrowUpSvg from "../../../assets/icon/svgJsx/ArrowTopSvg";

const Accordion = ({ label, children, className, openDefault = false }) => {
  const [isOpen, setIsOpen] = useState(openDefault);

  return (
    <div
      className={`accordion ${isOpen ? "" : "accordion-close"} ${
        className?.root ? className?.root : ""
      } `}
    >
      <div className={`accordion__label ${className?.label}`}>
        <div className="accordion__label__left">{label.leftPart}</div>
        <div className="accordion__label__right">
          {label.rightPart}

          {children && (
            <span
              className={`accordion__label__right__arrows ${
                isOpen
                  ? "accordion__label__right__arrows-arrowUp"
                  : "accordion__label__right__arrows-arrowDown"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? (
                <ArrowUpSvg width={24} height={24} viewBox="0 0 24 24" />
              ) : (
                <ArrowDownSvg width={24} height={24} viewBox="0 0 24 24" />
              )}
            </span>
          )}
        </div>
      </div>
      {isOpen && children && (
        <div className={`accordion-children ${className?.children}`}>{children}</div>
      )}
    </div>
  );
};

export default Accordion;
