import React, {useEffect, useState, useMemo, useContext} from "react";
import { useParams } from "react-router-dom";

import { technicalApi } from "~/api/dashboard/analysis/technicalApi";

import Accordion from "~/components/child/ui/Accordion";
import Modal from "~/components/child/ui/Modal";
import Button from "~/components/child/ui/Button";
import { Loading } from "~/components/child/ui/Loading";
import ImageSlider from "~/components/child/ui/ImageSlider";
import TechnicalPointPreview from "~/components/child/PointsPreview";
import AnalysisInformation from "~/components/child/AnalysisInformation";
import Comment from "~/components/child/Comment";
import { RightNav } from "~/components/child/RightNav";
import { EditHistory } from "~/components/child/editHistory";
import { PrivateNote } from "~/components/child/PrivateNote";
import { NotificationStatus } from "~/components/child/notification/NotificationStatus";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/technical/view/TVChartContainer";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { Status } from "./Status";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { fetchTechnicalDetails, fetchEditHistory } from "./helper";
import {MainChartContext} from "~/components/child/tradingViewMainChart/context/MainChartContext";

const TechnicalView = ({ user, permissions }) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState();
  const [historyDetails, setHistoryDetails] = useState({
    data: null,
    error: null,
  });
  const [activeAnalysis, setActiveAnalysis] = useState(id);
  const [totalValuesOfPrivateNotes, setTotalValuesOfPrivateNotes] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    theme: mainChartTheme,
    isLoading: mainChartIsLoading,
    favorites: mainChartFavorites,
    setFavorites: setMainChartFavorites,
    isChartDataValid,
    hasFavoriteChartDataChanged
  } = useContext(MainChartContext);

  const entryPriceList = details?.data?.details?.filter(
    (detail) => detail.point_type === "EP"
  );

  const takeProfitList = details?.data?.details?.filter(
    (detail) => detail.point_type === "TP"
  );

  const stopLossList = details?.data?.details?.filter(
    (detail) => detail.point_type === "SL"
  );

  useEffect(() => {
    if (activeAnalysis) {
      fetchTechnicalDetails(activeAnalysis, setDetails, setIsLoading);
    }
  }, [activeAnalysis]);

  const chartProps = useMemo(
    () => ({
      tradeBoundariesPoints: {
        ep: entryPriceList,
        sl: stopLossList,
        tp: takeProfitList,
      },
      pairDetail: details?.data?.analysis?.pair,
      initChartData: details?.data?.chart_data,
      initInterval: details?.data?.analysis?.time_frame?.time_frame,
      createdAt: details?.data?.analysis?.created_at,
      baseDateType: "view",
      theme: mainChartTheme,
      mainChartFavorites,
      setMainChartFavorites,
      hasFavoriteChartDataChanged
    }),
    [details, mainChartTheme]
  );

  const closeAnalysis = async () => {
    try {
      const result = await technicalApi.cancel(id);
      if (result && !result.error && result.data) {
        setDetails({ data: { ...details.data, is_closed: true } });
        setShowConfirmModal(false);
      } else if (result?.error) {
        setErrorMessage(result?.error);
      }
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  return (
    <>
      {!isLoading && details?.data ? (
        <div className="main__router__container">
          <div className="main__router__container__view-box-container scrollbar-style scrollbar-style-thumb">
            <div className="technical-view ">
              <AnalysisInformation
                id={id}
                user={
                  details?.data?.analysis?.user?.first_name +
                  " " +
                  details?.data?.analysis?.user?.last_name
                }
                pairs={details?.data.analysis.pair}
                timeFrame={details?.data.analysis?.time_frame?.time_frame}
                type="Technical"
                technicalType={
                  details?.data?.technical_type &&
                  details?.data?.technical_type[0]?.technical_type
                }
                positionType={changeWordFormatToTitleCase(
                  details?.data.analysis.position_type
                )}
                description={details?.data.analysis.description}
                createDateTime={{
                  time: details?.data?.analysis?.created_at?.split(" ")[1],
                  date: details?.data?.analysis?.created_at?.split(" ")[0],
                }}
                isEditable={
                  id === details.data.analysis.uuid &&
                  user?.uuid === details?.data?.analysis?.user?.uuid &&
                  !details?.data?.is_closed &&
                  details?.data?.status !== "win" &&
                  details?.data?.status !== "lose"
                }
                status={
                  <Status
                    isEditable={
                      id === details.data.analysis.uuid &&
                      user?.uuid === details?.data?.analysis?.user?.uuid &&
                      !details?.data?.is_closed
                    }
                    setShowConfirmModal={() => setShowConfirmModal(true)}
                    currentStatus={
                      details?.data?.is_closed
                        ? "Closed"
                        : details?.data?.technical_type &&
                          details?.data?.technical_type[0]?.technical_type === "SETUP"
                        ? details?.data?.status || "No Touch"
                        : entryPriceList?.length > 0 &&
                          takeProfitList?.length > 0 &&
                          stopLossList?.length > 0
                        ? details?.data?.status || "No Touch"
                        : undefined
                    }
                  />
                }
                notifications={
                  <NotificationStatus
                    users={
                      details?.data?.notifications &&
                      details?.data?.notifications[0]?.receivers?.map((item) => ({
                        value: item?.first_name + item.last_name,
                      }))
                    }
                  />
                }
              />

              <div className={`${chartSize?.mode2}`}>
                {isChartDataValid ? (
                  <TVChartContainer {...chartProps} />
                ) : !mainChartIsLoading ? (
                  <DataFailureAlert />
                ) : (
                  <Loading />
                )}
              </div>
              {details?.data?.pictures?.length > 0 && (
                <ImageSlider images={details?.data?.pictures} />
              )}
              {(entryPriceList?.length > 0 ||
                takeProfitList?.length > 0 ||
                stopLossList?.length > 0) && (
                <div className="technical-view__entries">
                  {entryPriceList?.length > 0 && (
                    <Accordion
                      className={{
                        root: "technical-view__entries__accordion__ep",
                      }}
                      label={{
                        leftPart: (
                          <span className="technical-view__entries__accordion__ep__label">
                            EP
                            <span className="technical-view__entries__accordion__ep__label-count">
                              ({entryPriceList?.length})
                            </span>
                          </span>
                        ),
                      }}
                      openDefault={true}
                    >
                      {entryPriceList?.map((epItem, index) => {
                        return (
                          <TechnicalPointPreview
                            label={`EP ${index + 1}`}
                            key={index}
                            value={`${epItem.base_amount}${
                              epItem.max_amount !== 0 ? `-${epItem.max_amount}` : ""
                            }`}
                            note={epItem.point_description}
                          />
                        );
                      })}
                    </Accordion>
                  )}
                  {takeProfitList?.length > 0 && (
                    <Accordion
                      className={{
                        root: "technical-view__entries__accordion__tp",
                      }}
                      label={{
                        leftPart: (
                          <span className="technical-view__entries__accordion__tp__label">
                            TP
                            <span className="technical-view__entries__accordion__tp__label-count">
                              ({takeProfitList?.length})
                            </span>
                          </span>
                        ),
                      }}
                      openDefault={true}
                    >
                      {takeProfitList?.map((tpItem, index) => {
                        return (
                          <TechnicalPointPreview
                            label={`TP ${index + 1}`}
                            key={index}
                            value={`${tpItem.base_amount}${
                              tpItem.max_amount !== 0 ? `-${tpItem.max_amount}` : ""
                            }`}
                            note={tpItem.point_description}
                            riskToReward={tpItem.rr}
                          />
                        );
                      })}
                    </Accordion>
                  )}

                  {stopLossList?.length > 0 && (
                    <div className="technical-view__entries__sl">
                      {stopLossList?.map((slItem, index) => {
                        return (
                          <TechnicalPointPreview
                            label={`SL ${index + 1}`}
                            key={index}
                            value={`${slItem.base_amount}${
                              slItem.max_amount !== 0 ? `-${slItem.max_amount}` : ""
                            }`}
                            note={slItem.point_description}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
            {(permissions?.new_comment || permissions?.comment_list) && (
              <Comment permissions={permissions} section="analytics" id={id} />
            )}
          </div>
          <RightNav>
            <Accordion
              className={{ root: "px-0 border-b border-storm-30" }}
              label={{
                leftPart: <span>History</span>,
              }}
              openDefault={true}
            >
              <EditHistory
                setActiveAnalysis={setActiveAnalysis}
                activeAnalysis={activeAnalysis}
                id={id}
                historyDetails={historyDetails}
                fetchEditHistory={(activeAnalysis) =>
                  fetchEditHistory(activeAnalysis, setHistoryDetails)
                }
                uuidPath={["deleted_analytic", "analysis", "uuid"]}
                />
            </Accordion>
            <Accordion
              className={{ root: "px-0" }}
              label={{
                leftPart: (
                  <span>
                    Private Note
                    {totalValuesOfPrivateNotes > 0 && (
                      <span className="total">({totalValuesOfPrivateNotes})</span>
                    )}
                  </span>
                ),
              }}
              openDefault={true}
            >
              {(permissions?.new_private_note || permissions?.private_note_list) && (
                <PrivateNote
                  permissions={permissions}
                  section="analytics"
                  setTotalCount={(value) => setTotalValuesOfPrivateNotes(value)}
                  id={id}
                />
              )}
            </Accordion>
          </RightNav>
          {showConfirmModal && (
            <Modal>
              <p className="text-oil-dark font-roboto text-base font-normal leading-snug m-0">
                you are changing analysis status from{" "}
                <span className="text-sky-40">{details?.data?.status} to close</span>, if
                you are okay, press save changes button.
              </p>
              <div className="flex items-center flex-row-reverse gap-4 justify-between">
                <Button
                  disabled={isLoading || errorMessage?.length > 0}
                  onClick={() => closeAnalysis()}
                  type="submit"
                  variant="primary"
                  className="w-full"
                  isLoading={isLoading}
                >
                  Save changes
                </Button>

                <Button
                  onClick={() => {
                    setErrorMessage("");
                    setShowConfirmModal(false);
                  }}
                  variant="outline"
                  className="w-full"
                >
                  Cancel
                </Button>
              </div>
              {errorMessage && (
                <p className="font-roboto text-sm text-infrared-key text-center">
                  {errorMessage}
                </p>
              )}
            </Modal>
          )}
        </div>
      ) : !isLoading ? (
        <div className="main__router__container">
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">
              There isn't any technical analysis with this Id!
            </h4>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TechnicalView;
