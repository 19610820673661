import React, { useState } from "react";
import arrowLeft from "../../assets/icon/arrows/arrow-left.svg";
import arrowRight from "../../assets/icon/arrows/arrow-right.svg";
import FullChart from "./FullChart";
import TokensList from "./TokensList";
import HeatMapList from "./HeatMap";
import Setup from "./Setup";
import Tools from "./Tools";
import Settings from "./Settings";

const Nav = ({ breadcrumbItems, user }) => {
  const [navBoxIsOpen, setNavBoxIsOpen] = useState(true);
  const [heatMapIsOpen, setHeatMapIsOpen] = useState(false);
  const [setupIsOpen, setSetupIsOpen] = useState(false);
  const [toolsIsOpen, setToolsIsOpen] = useState(false);
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);

  const handleClickArrowNavBoxIcon = () => {
    setHeatMapIsOpen(false);
  };

  return (
    <nav className={`nav ${!navBoxIsOpen && "isClose"}`}>
      <div className="nav__list">
        <ul>
          <div className="nav__list__box-list-1">
            <li className={`nav__list__title ${!navBoxIsOpen ? "isClose" : ""}`}>
              <h2>Trade Assistant</h2>
              <i
                onClick={() => {
                  handleClickArrowNavBoxIcon();
                  setNavBoxIsOpen(!navBoxIsOpen);
                }}
              >
                <img src={navBoxIsOpen ? arrowLeft : arrowRight} alt="" />
              </i>
            </li>

            <FullChart
              nameItem={"Chart"}
              breadcrumbItems={breadcrumbItems}
              navBoxIsOpen={navBoxIsOpen}
              user={user}
            />
            <TokensList
              nameItem={"Coin list"}
              breadcrumbItems={breadcrumbItems}
              navBoxIsOpen={navBoxIsOpen}
              user={user}
            />

            <HeatMapList
              parentCategoryName={"HeatMap"}
              breadcrumbItems={breadcrumbItems}
              heatMapIsOpen={heatMapIsOpen}
              setHeatMapIsOpen={setHeatMapIsOpen}
              navBoxIsOpen={navBoxIsOpen}
              user={user}
            />

            {user?.role[0]?.permissions?.full_access && (
              <Setup
                parentCategoryName={"Setup List"}
                breadcrumbItems={breadcrumbItems}
                setupIsOpen={setupIsOpen}
                setSetupIsOpen={setSetupIsOpen}
                navBoxIsOpen={navBoxIsOpen}
                user={user}
              />
            )}

            <Tools
              parentCategoryName={"Tools"}
              breadcrumbItems={breadcrumbItems}
              toolsIsOpen={toolsIsOpen}
              setToolsIsOpen={setToolsIsOpen}
              navBoxIsOpen={navBoxIsOpen}
              user={user}
            />

            {user?.role[0]?.permissions.full_access && (
              <Settings
                parentCategoryName={"Settings"}
                breadcrumbItems={breadcrumbItems}
                navBoxIsOpen={navBoxIsOpen}
                settingsIsOpen={settingsIsOpen}
                setSettingsIsOpen={setSettingsIsOpen}
                user={user}
              />
            )}
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
