import { getLanguageFromURL } from "../utils/helpers/tools/getLanguageFromURL";
import { validateInput } from "../utils/helpers/tools/validateInput";
import {apiConfig} from "~/utils/constants";

export const baseChartConfig = {
  defaultProps: {
    charts_storage_url: "https://saveload.tradingview.com",
    charts_storage_api_version: "1.1",
    client_id: "tradingview.com",
    user_id: "public_user_id",
    customCssUrl: "css/style.css",
    fullscreen: false,
    autosize: true,
    studies_overrides: {},
    locale: getLanguageFromURL() || "en",
    library_path: "/charting_library/",
    timezone: "Asia/Tehran",
    time_frames: [],
  },
  datafeedUrl: apiConfig.TV_CHART_DATA_FEED,
  createDatafeed: (datafeedUrl) =>
    new window.Datafeeds.UDFCompatibleDatafeed(datafeedUrl),
};

const specificConfigs = {
  technical: {
    create: {
      disabledFeatures: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "disable_resolution_rebuild",
        "hide_object_tree_and_price_scale_exchange_label",
        "symbol_search_hot_key",
        "header_symbol_search",
        "edit_buttons_in_legend",
        "custom_timezones",
        "header_saveload",
        "study_templates",
      ],
      enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"]
    },
    detail: {
      disabledFeatures: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "disable_resolution_rebuild",
        "hide_object_tree_and_price_scale_exchange_label",
        "symbol_search_hot_key",
        "edit_buttons_in_legend",
        "header_saveload",
        "timezone_menu",
        "property_pages",
        "study_templates",
      ],
      enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"],
    },
  },
  fundamental: {
    create: {
      disabledFeatures: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "disable_resolution_rebuild",
        "hide_object_tree_and_price_scale_exchange_label",
        "symbol_search_hot_key",
        "header_symbol_search",
        "edit_buttons_in_legend",
        "header_resolutions",
        "custom_timezones",
        "header_saveload",
        "study_templates"
      ],
      enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"],
    },
    detail: {
      disabledFeatures: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "disable_resolution_rebuild",
        "hide_object_tree_and_price_scale_exchange_label",
        "symbol_search_hot_key",
        "header_symbol_search",
        "edit_buttons_in_legend",
        "header_saveload",
        "custom_timezones",
        "property_pages",
        "timezone_menu",
        "study_templates",
      ],
      enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"],
    },
  },
  heatmap: {
    disabledFeatures: [
      "use_localstorage_for_settings",
      "header_symbol_search",
      "disable_resolution_rebuild",
      "hide_object_tree_and_price_scale_exchange_label",
      "symbol_search_hot_key",
      "header_symbol_search",
      "edit_buttons_in_legend",
      "header_saveload",
      "custom_timezones",
      "property_pages",
      "timezone_menu",
      "header_undo_redo",
      "study_templates",
    ],
    enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"],
  },
  setup: {
    disabledFeatures: [
      "use_localstorage_for_settings",
      "header_symbol_search",
      "disable_resolution_rebuild",
      "hide_object_tree_and_price_scale_exchange_label",
      "symbol_search_hot_key",
      "edit_buttons_in_legend",
      "header_saveload",
      "timezone_menu",
      "property_pages",
      "study_templates",
    ],
    enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"],
  },
  imbalances: {
    disabledFeatures: [
      "use_localstorage_for_settings",
      "header_symbol_search",
      "disable_resolution_rebuild",
      "hide_object_tree_and_price_scale_exchange_label",
      "symbol_search_hot_key",
      "header_symbol_search",
      "edit_buttons_in_legend",
      "header_saveload",
      "custom_timezones",
    ],
    enabledFeatures: ["show_symbol_logos", "accessible_keyboard_shortcuts", "items_favoriting"],
  },
  mainChart: {
    disabledFeatures: [
      "use_localstorage_for_settings",
      "hide_object_tree_and_price_scale_exchange_label",
    ],
    enabledFeatures: [
      "show_symbol_logos",
      "accessible_keyboard_shortcuts",
      "chart_template_storage",
      "items_favoriting",
      "show_object_tree",
    ],
  },

};

export function createChartConfig(type, additionalConfig = {}) {
  if (!specificConfigs[type]) {
    validateInput(null, `(type) => '${type}'`, 'createChartConfig');
  }

  return {
    baseChartConfig: {
      defaultProps: { ...baseChartConfig.defaultProps, ...additionalConfig },
      datafeedUrl: baseChartConfig.datafeedUrl,
      createDatafeed: baseChartConfig.createDatafeed,
    },
    specificConfigs: {
      ...specificConfigs[type],
    },
  };
}
