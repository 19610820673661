import React, {useState, useEffect, useMemo, useContext} from "react";
import { useParams } from "react-router-dom";

import { fundamentalApi } from "~/api/dashboard/analysis/fundamentalApi";

import AnalysisInformation from "~/components/child/AnalysisInformation";
import Comment from "~/components/child/Comment";
import { RightNav } from "~/components/child/RightNav";
import { PrivateNote } from "~/components/child/PrivateNote";
import FundamentalEntry from "~/components/child/FundamentalEntry";
import { Loading } from "~/components/child/ui/Loading";
import ImageSlider from "~/components/child/ui/ImageSlider";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/fundamental/view/TVChartContainer";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import {MainChartContext} from "~/components/child/tradingViewMainChart/context/MainChartContext";

const FundamentalView = ({ permissions }) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState();

  const {
      theme: mainChartTheme,
      isLoading: mainChartIsLoading,
      favorites: mainChartFavorites,
      setFavorites: setMainChartFavorites,
      isChartDataValid,
      hasFavoriteChartDataChanged
  } = useContext(MainChartContext);

  const chartProps = useMemo(
    () => ({
      pairDetail: details?.data?.analysis?.pair,
      initInterval: "D",
      initChartData: details?.data?.chart_data,
      createdAt: details?.data?.analysis?.created_at,
      details: details?.data?.details,
      positionType: details?.data.analysis.position_type,
      theme: mainChartTheme,
      mainChartFavorites,
      setMainChartFavorites,
      hasFavoriteChartDataChanged
    }),
    [details?.data?.chart_data, mainChartTheme]
  );


  useEffect(() => {
    const fetchFundamentalDetails = async () => {
      try {
        const result = await fundamentalApi.single(id);

        setDetails(result.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pairs data:", error);
      }
    };

    if (id) fetchFundamentalDetails();
  }, [id]);

  return (
    <>
      {!isLoading && details?.data ? (
        <div className="main__router__container">
          <div className="main__router__container__view-box-container scrollbar-style scrollbar-style-thumb">
            <div className="fundamental-view ">
              <AnalysisInformation
                user={
                  details?.data?.analysis?.user?.first_name +
                  " " +
                  details?.data?.analysis?.user?.last_name
                }
                pairs={details?.data.analysis.pair}
                type="Fundamental"
                description={details?.data.analysis.description}
                createDateTime={{
                  time: details?.data?.analysis?.created_at?.split(" ")[1],
                  date: details?.data?.analysis?.created_at?.split(" ")[0],
                }}
                positionType={
                  details?.data.analysis.position_type &&
                  changeWordFormatToTitleCase(details?.data.analysis.position_type)
                }
              />

              <div className={`${chartSize?.mode2}`}>
                { isChartDataValid ? (
                  <TVChartContainer {...chartProps} />
                ) : !mainChartIsLoading ? (
                  <DataFailureAlert />
                ) : (
                  <Loading />
                )}
              </div>
              {details?.data?.pictures.length > 0 && (
                <ImageSlider images={details?.data?.pictures} />
              )}

              <div className="fundamental-view__entries">
                {details?.data?.details?.map((item, index) => (
                  <FundamentalEntry
                    id={index}
                    label={item?.title}
                    date={item?.analysis_date?.split(" ")[0]}
                    description={item?.analysis_description}
                  />
                ))}
              </div>
            </div>
            {(permissions?.new_comment || permissions?.comment_list) && (
              <Comment permissions={permissions} section="analytics" id={id} />
            )}
          </div>
          {(permissions?.new_private_note || permissions?.private_note_list) && (
            <RightNav>
              <PrivateNote permissions={permissions} section="analytics" id={id} />
            </RightNav>
          )}
        </div>
      ) : !isLoading ? (
        <div className="main__router__container">
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">
              There isn't any fundamental analysis with this Id!
            </h4>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FundamentalView;
