import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination/lib/Pagination";

import { tokensApi } from "~/api/dashboard/tokensListApi";

import { Loading } from "~/components/child/ui/Loading";
import Dropdown from "~/components/child/ui/DropDown";
import InputBase from "~/components/child/ui/InputBase";

import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import StarSvg from "~/assets/icon/svgJsx/StarSvg";
import CoinSvg from "~/assets/icon/svgJsx/CoinSvg";
import SearchSvg from "~/assets/icon/svgJsx/SearchSvg";
import coinSvg from "~/assets/icon/coin.svg";
import ascendingSvg from "~/assets/icon/ascending.svg";
import descendingSvg from "~/assets/icon/descending.svg";
import removeSvg from "~/assets/icon/remove.svg";
import sortSvg from "~/assets/icon/sort.svg";
import arrowFill from "~/assets/icon/arrows/arrowFill.svg";

const TokensList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [listItems, setListItems] = useState([]);

  const [queryParams, setQueryParams] = useState({
    sort_by: "rank",
    sort_order: "asc",
    favourite: 0,
    page_number: 1,
    per_page: 10,
    search: "",
  });

  const [searchTerm, setSearchTerm] = useState(queryParams.search);

  const fetchTokenList = async (queryParams) => {
    setIsLoading(true);
    try {
      const result = await tokensApi.list(queryParams);
      setListItems(result.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error("Error fetching coins list data:", error);
    }
  };

  const onFavoriteClick = async (id, status) => {
    try {
      const result = await tokensApi.favorite(id, { is_favorite: status });
      if (!result.error)
        setListItems((prevItems) => {
          return {
            ...prevItems,
            data: prevItems.data.map((item) =>
              item.id === id ? { ...item, favorite: !item.favorite } : item
            ),
          };
        });
    } catch (error) {
      console.error("Error making coin favorite:", error);
    }
  };

  const handlePageChange = (page) => {
    setQueryParams((prev) => ({ ...prev, page_number: page }));
  };

  useEffect(() => {
    if (queryParams.search.length > 1 || queryParams.search.length === 0) {
      fetchTokenList(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm !== queryParams.search) {
        setQueryParams((prev) => ({ ...prev, page_number: 0, search: searchTerm }));
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, queryParams.search]);

  const stateDetection = (number) => {
    if (number > 0)
      return { orderBy: "ascending", img: <img src={ascendingSvg} alt="ascending" /> };
    else
      return { orderBy: "descending", img: <img src={descendingSvg} alt="descending" /> };
  };

  const handleSortOperation = (sortBy) =>
    setQueryParams({
      ...queryParams,
      sort_by: sortBy,
      sort_order:
        queryParams.sort_by === sortBy
          ? queryParams.sort_order === "asc"
            ? "desc"
            : "asc"
          : "asc",
    });

  return (
    <div className="main__router__container">
      <div className="main__router__container__list-box-container gap-3">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-fit items-center justify-between gap-4">
            <button
              onClick={() =>
                setQueryParams({ ...queryParams, page_number: 0, favourite: 0 })
              }
              className={`bg-base flex w-fit gap-2 py-2 px-3 items-center rounded-xl text-sm font-medium text-sky-key ${
                queryParams.favourite === 1 ? "" : "outline outline-1 outline-sky-key"
              }`}
            >
              {queryParams.favourite === 1 ? (
                <CoinSvg />
              ) : (
                <img src={coinSvg} alt="coin" />
              )}
              All
            </button>
            <button
              onClick={() =>
                setQueryParams({
                  ...queryParams,
                  page_number: 0,
                  favourite: queryParams.favourite === 0 ? 1 : 0,
                })
              }
              className={` bg-base flex w-fit gap-2 py-2 px-3 items-center rounded-xl text-sm font-medium text-sky-key ${
                queryParams.favourite === 0 ? "" : "outline outline-1 outline-sky-key"
              }`}
            >
              <StarSvg isFilled={queryParams.favourite === 1 ? true : false} />
              Favorite
            </button>
          </div>
          <InputBase
            className={{
              root: "w-[26.5%] bg-base !border-none",
            }}
          >
            <div className="flex items-center justify-between gap-1 px-2 w-full">
              <SearchSvg className="w-[10%]" color="#007BFF" />
              <input
                className="w-[93%] text-left pr-2 outline-none py-1.5 text-sm placeholder:text-sky-key"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
                type="text"
              />
              {queryParams.search.length > 0 && (
                <button onClick={() => setSearchTerm("")}>
                  <img src={removeSvg} alt="remove" />
                </button>
              )}
            </div>
          </InputBase>
        </div>
        {!isLoading && listItems?.data?.length > 0 ? (
          <div>
            <div className="list-table-cstm-one max-h-[auto] overflow-hidden relative">
              <table className="list-table-cstm-one__content-table table-fixed w-full">
                <thead className="sticky top-0 z-10">
                  <tr>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-start ${
                          queryParams.sort_by === "rank" ? "font-robotoMed" : ""
                        }`}
                      >
                        {queryParams.sort_by === "rank" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        #
                        <button onClick={() => handleSortOperation("rank")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-start ${
                          queryParams.sort_by === "name" ? "font-robotoMed" : ""
                        }`}
                      >
                        {queryParams.sort_by === "name" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        Coin{" "}
                        <button onClick={() => handleSortOperation("name")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-center ${
                          queryParams.sort_by === "price" ? "font-robotoMed" : ""
                        }`}
                      >
                        {queryParams.sort_by === "price" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        Price{" "}
                        <button onClick={() => handleSortOperation("price")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-center ${
                          queryParams.sort_by === "one_hour_change"
                            ? "font-robotoMed"
                            : ""
                        }`}
                      >
                        {queryParams.sort_by === "one_hour_change" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        1h%{" "}
                        <button onClick={() => handleSortOperation("one_hour_change")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-center ${
                          queryParams.sort_by === "twenty_four_hour_change"
                            ? "font-robotoMed"
                            : ""
                        }`}
                      >
                        {queryParams.sort_by === "twenty_four_hour_change" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        24h%{" "}
                        <button
                          onClick={() => handleSortOperation("twenty_four_hour_change")}
                        >
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-center ${
                          queryParams.sort_by === "seven_day_change"
                            ? "font-robotoMed"
                            : ""
                        }`}
                      >
                        {queryParams.sort_by === "seven_day_change" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        7d%{" "}
                        <button onClick={() => handleSortOperation("seven_day_change")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-center ${
                          queryParams.sort_by === "volume" ? "font-robotoMed" : ""
                        }`}
                      >
                        {queryParams.sort_by === "volume" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        24h Volume{" "}
                        <button onClick={() => handleSortOperation("volume")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>
                      <div
                        className={`gap-1 flex items-center justify-center ${
                          queryParams.sort_by === "market_cap" ? "font-robotoMed" : ""
                        }`}
                      >
                        {queryParams.sort_by === "market_cap" && (
                          <img
                            src={arrowFill}
                            className={
                              queryParams.sort_order === "asc" ? "" : " -rotate-180"
                            }
                            alt="arrow"
                          />
                        )}
                        Market Cap{" "}
                        <button onClick={() => handleSortOperation("market_cap")}>
                          <img src={sortSvg} alt="sort" />
                        </button>
                      </div>
                    </th>
                    <th>Detail</th>
                  </tr>
                </thead>

                <tbody className="block  scrollbar-style scrollbar-style-thumb overflow-y-auto max-h-[62vh] w-[900%]">
                  {listItems?.data?.map((item, index) => (
                    <tr className="table table-fixed w-full" key={index}>
                      <td>
                        <button
                          onClick={() =>
                            onFavoriteClick(item.id, !item.favorite)
                          }
                          className="flex items-center justify-center gap-2"
                        >
                          <StarSvg
                            fill={"#EBB513"}
                            width={32}
                            height={32}
                            stroke="#668899"
                            isFilled={item.favorite}
                          />
                          {item.market_cap_rank}
                        </button>
                      </td>
                      <td className="flex justify-start mx-auto w-full gap-2.5 items-center">
                        <img src={item.image} className="w-8 h-8" alt="coin" />
                        <p className="flex flex-col text-sm justify-center items-start">
                          <span className=" font-robotoMed text-left">{item.name}</span>
                          <span>{item.symbol.toUpperCase()}</span>
                        </p>
                      </td>

                      <td>
                        {item?.current_price ? (
                          <>
                            {item?.current_price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 10,
                            })}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td>
                        <p
                          className={`flex items-center justify-center gap-0.5 ${
                            item.change_percentage_1h
                              ? stateDetection(item.change_percentage_1h).orderBy ===
                                "ascending"
                                ? "text-leaf-key"
                                : "text-infrared-key"
                              : ""
                          }`}
                        >
                          {item.change_percentage_1h ? (
                            <>
                              {stateDetection(item.change_percentage_1h).img}
                              {Math.floor(item.change_percentage_1h * 100) / 100}%
                            </>
                          ) : (
                            <>-</>
                          )}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`flex items-center justify-center gap-0.5 ${
                            item.change_percentage_24h
                              ? stateDetection(item.change_percentage_24h).orderBy ===
                                "ascending"
                                ? "text-leaf-key"
                                : "text-infrared-key"
                              : ""
                          }`}
                        >
                          {item.change_percentage_24h ? (
                            <>
                              {stateDetection(item.change_percentage_24h).img}
                              {Math.floor(item.change_percentage_24h * 100) / 100}%
                            </>
                          ) : (
                            <>-</>
                          )}
                        </p>
                      </td>

                      <td>
                        <p
                          className={`flex items-center justify-center gap-0.5 ${
                            item.change_percentage_7d
                              ? stateDetection(item.change_percentage_7d).orderBy ===
                                "ascending"
                                ? "text-leaf-key"
                                : "text-infrared-key"
                              : ""
                          }`}
                        >
                          {item.change_percentage_7d ? (
                            <>
                              {stateDetection(item.change_percentage_7d).img}
                              {Math.floor(item.change_percentage_7d * 100) / 100}%
                            </>
                          ) : (
                            <>-</>
                          )}
                        </p>
                      </td>
                      <td>
                        $
                        {item?.total_volume?.toLocaleString("en-US", {
                          style: "decimal",
                          maximumFractionDigits: 0,
                        })}
                      </td>
                      <td>
                        $
                        {item?.market_cap?.toLocaleString("en-US", {
                          style: "decimal",
                          maximumFractionDigits: 0,
                        })}
                      </td>

                      <td className="list-table-cstm-one__content-table__details-arrow">
                        <ArrowRightSvg className="m-auto" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination-one flex items-center justify-between w-full">
              <Pagination
                current={queryParams.page_number}
                total={listItems?.meta?.total}
                pageSize={queryParams.per_page}
                onChange={handlePageChange}
                showSizeChanger={false}
                prevIcon={<ArrowLeftSvg />}
                nextIcon={<ArrowRightSvg />}
              />
              <div className="flex items-center gap-2">
                <span className="text-sky-70 text-sm font-roboto min-w-max">
                  Show Row
                </span>

                <Dropdown
                  className={{
                    root: "rounded-lg",
                    input: "py-0.5 pl-2.5 border-storm-30  rounded-xl",
                    child: "gap-1",
                  }}
                  value={{ key: queryParams.per_page, value: queryParams.per_page }}
                  optionsList={[
                    { key: "10", value: "10" },
                    { key: "50", value: "50" },
                    { key: "100", value: "100" },
                    { key: "All", value: "250" },
                  ].map((item) => ({
                    key: item.key,
                    value: item.value,
                  }))}
                  renderItem={(item) => (
                    <span className=" text-sm text-sky-dark font-roboto w-full text-left">
                      {item.key}
                    </span>
                  )}
                  onSelect={(item) => {
                    setQueryParams({
                      ...queryParams,
                      page_number: 0,
                      per_page: Number(item.value),
                    });
                  }}
                  openOnTop={true}
                />
              </div>
            </div>
          </div>
        ) : !isLoading ? (
          <div className="empty-analysis">
            <h4 className="empty-analysis__title">No results!</h4>
          </div>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default TokensList;
