import React, {useState, useEffect, useMemo, useContext} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { setupApi } from "~/api/dashboard/setupApi";

import Accordion from "~/components/child/ui/Accordion";
import { Loading } from "~/components/child/ui/Loading";
import Button from "~/components/child/ui/Button";
import { RightNav } from "~/components/child/RightNav";
import Comment from "~/components/child/Comment";
import { EditHistory } from "~/components/child/editHistory";
import { PrivateNote } from "~/components/child/PrivateNote";
import SetupInformation from "~/components/child/AnalysisInformation";
import SetupPointsPreview from "~/components/child/PointsPreview";
import { NotificationStatus } from "~/components/child/notification/NotificationStatus";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/setup/TVChartContainer";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { ChangeStateConfirm, History } from "./State";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { sortDescending, sortAscending } from "../helper";
import {MainChartContext} from "~/components/child/tradingViewMainChart/context/MainChartContext";

const RiskParams = ({ label, value }) => (
  <div className="flex items-center justify-between rounded-lg py-4 bg-base p-4">
    <h4 className="text-base text-storm-50">{label}</h4>
    <span className="text-oil-60 font-roboto text-xs">{value}</span>
  </div>
);

const View = ({ permissions, user }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState();
  const [historyDetails, setHistoryDetails] = useState({
    data: null,
    error: null,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [activeAnalysis, setActiveAnalysis] = useState(id);
  const [totalValuesOfPrivateNotes, setTotalValuesOfPrivateNotes] = useState(0);
  const {
    theme: mainChartTheme,
    isLoading: mainChartIsLoading,
    favorites: mainChartFavorites,
    setFavorites: setMainChartFavorites,
    isChartDataValid,
    hasFavoriteChartDataChanged
  } = useContext(MainChartContext);

  const fetchSetupDetails = async (id) => {
    try {
      const result = await setupApi.single(id);

      setDetails(result?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  const fetchEditHistory = async (id) => {
    try {
      const result = await setupApi.editHistory(id);
      if (result?.data) setHistoryDetails({ data: result?.data, error: null });
      else if (result?.error)
        setHistoryDetails({ data: null, error: result?.error.message });
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  useEffect(() => {
    if (activeAnalysis) {
      fetchSetupDetails(activeAnalysis);
    }
  }, [activeAnalysis]);

  const entryPriceList =
    details?.data?.type === "LONG" && details?.data?.points?.ep
      ? sortAscending(details?.data?.points?.ep)
      : sortDescending(details?.data?.points?.ep);

  const takeProfitList =
    details?.data?.type === "LONG" && details?.data?.points?.tp
      ? sortAscending(details?.data?.points?.tp)
      : sortDescending(details?.data?.points?.tp);

  const stopLossList =
    details?.data?.type === "LONG" && details?.data?.points?.sl
      ? sortDescending(details?.data?.points?.sl)
      : sortAscending(details?.data?.points?.sl);

  const chartProps = useMemo(
    () => ({
      tradeBoundariesPoints: {
        ep: entryPriceList,
        sl: stopLossList,
        tp: takeProfitList,
      },
      pairDetail: details?.data?.pair,
      initInterval: details?.data?.heatmap?.timeframe?.time_frame,
      theme: mainChartTheme,
      createdAt: details?.data?.created_at,
      baseDateType: "view",
      mainChartFavorites,
      setMainChartFavorites,
      hasFavoriteChartDataChanged
    }),
    [details, mainChartTheme]
  );

  const changeStateBtn = (currentState) => {
    if (currentState.includes("Entry")) {
      return "Terminate";
    } else if (currentState.includes("Target")) {
      return "Terminate target";
    } else if (currentState.includes("Stop")) {
      return "Terminate stop";
    } else {
      return "Cancel";
    }
  };

  return (
    <>
      {permissions?.full_access ? (
        <>
          {!isLoading && details?.data ? (
            <>
              <div className="main__router__container">
                <div className="main__router__container__view-box-container scrollbar-style scrollbar-style-thumb">
                  <div className="flex flex-col h-auto pb-8 gap-6">
                    <div className="flex justify-between items-center">
                      <h3 className="text-storm-80 text-lg">Setup Details</h3>
                      <div className="flex items-center gap-1.5">
                        {details?.data?.user.uuid === user.uuid &&
                          details?.data?.state !== "Cancel" &&
                          details?.data?.state !== "Full Stop" &&
                          details?.data?.state !== "Full Target" && (
                            <p className="flex items-center gap-2 font-roboto text-base text-storm-key">
                              You can change the State to:{" "}
                              <Button
                                className="!rounded-xl disabled:cursor-not-allowed text-base font-robotoMed gap-2 py-1.5 px-3"
                                variant="outline"
                                onClick={() => setShowConfirmModal(true)}
                                disabled={
                                  isStatusChanged || !details?.data?.status_update
                                }
                              >
                                {changeStateBtn(details?.data?.state)}
                                <span className="font-roboto">{">"}</span>
                              </Button>
                            </p>
                          )}
                        {details?.data?.is_editable && (
                          <Button
                            className="!rounded-xl disabled:cursor-not-allowed text-base font-robotoMed gap-2 py-1.5 px-3"
                            variant="primary"
                            onClick={() =>
                              navigate(
                                `/editSetup?id=${details?.data?.uuid}&heatmapId=${details?.data?.heatmap?.uuid}`
                              )
                            }
                          >
                            Edit Setup
                          </Button>
                        )}
                      </div>
                    </div>
                    <SetupInformation
                      id={id}
                      type="Setup"
                      user={
                        changeWordFormatToTitleCase(details?.data?.user?.first_name) +
                        " " +
                        changeWordFormatToTitleCase(details?.data?.user?.last_name)
                      }
                      pairs={details?.data.pair}
                      timeFrame={details?.data?.heatmap?.timeframe?.time_frame}
                      positionType={changeWordFormatToTitleCase(details?.data?.type)}
                      vault={details?.data?.vault?.name}
                      notifications={
                        <NotificationStatus
                          users={details?.data?.notifications[0]?.receivers?.map(
                            (item) => ({
                              value: item?.first_name + item.last_name,
                            })
                          )}
                        />
                      }
                    />
                    <div className="grid grid-cols-3 gap-4">
                      <RiskParams
                        label="Max loss"
                        value={`${details?.data?.mmd}${
                          details?.data?.mmd !== 0 ? "%" : ""
                        }`}
                      />
                      <RiskParams
                        label="Max Profit"
                        value={`${details?.data?.max_profit}${
                          details?.data?.max_profit !== 0 ? "%" : ""
                        }`}
                      />
                      <RiskParams label="R:R" value={details?.data?.rr} />
                    </div>

                    {details?.data?.description && (
                      <p className="text-base text-oil-60 font-yekanBakh text-right">
                        {details?.data?.description}
                      </p>
                    )}
                    <div className={`${chartSize?.mode2}`}>
                      { isChartDataValid && details ? (
                        <TVChartContainer {...chartProps} />
                      ) : !mainChartIsLoading ? (
                        <DataFailureAlert />
                      ) : (
                        <Loading />
                      )}
                    </div>
                    <div className="flex flex-col rounded-lg bg-storm-light gap-4 py-4 px-2.5">
                      <h3 className="text-lg text-storm-70">Trade Parameters</h3>

                      {entryPriceList?.length > 0 && (
                        <Accordion
                          className={{
                            root: "p-0",
                            label: "bg-sky-10 rounded-lg py-1.5 px-2.5",
                            children: "gap-2 flex flex-col mb-0",
                          }}
                          label={{
                            leftPart: (
                              <span className="text-sky-key text-sm font-robotoMed">
                                EP{" "}
                                <span className="font-roboto text-storm-key">
                                  ({entryPriceList.length})
                                </span>
                              </span>
                            ),
                          }}
                          openDefault={true}
                        >
                          {entryPriceList?.map((epItem, index) => {
                            return (
                              <SetupPointsPreview
                                label={`EP ${index + 1}`}
                                key={index}
                                value={epItem.amount}
                                weight={epItem.weight}
                                isClosed={epItem?.is_closed}
                              />
                            );
                          })}
                        </Accordion>
                      )}
                      {takeProfitList?.length > 0 && (
                        <Accordion
                          className={{
                            root: "p-0",
                            label: "bg-sky-10 rounded-lg py-1.5 px-2.5",
                            children: "gap-2 flex flex-col mb-0",
                          }}
                          label={{
                            leftPart: (
                              <span className="text-leaf-key text-sm font-robotoMed">
                                TP{" "}
                                <span className="font-roboto text-storm-key">
                                  ({takeProfitList.length})
                                </span>
                              </span>
                            ),
                          }}
                          openDefault={true}
                        >
                          {takeProfitList?.map((tpItem, index) => {
                            return (
                              <SetupPointsPreview
                                label={`TP ${index + 1}`}
                                key={index}
                                value={tpItem.amount}
                                weight={tpItem.weight}
                                isClosed={tpItem?.is_closed}
                                riskToReward={tpItem?.rr}
                              />
                            );
                          })}
                        </Accordion>
                      )}

                      {stopLossList?.length > 0 && (
                        <Accordion
                          className={{
                            root: "p-0",
                            label: "bg-sky-10 rounded-lg py-1.5 px-2.5",
                            children: "gap-2 flex flex-col mb-0",
                          }}
                          label={{
                            leftPart: (
                              <span className="text-infrared-key text-sm font-robotoMed">
                                SL{" "}
                                <span className="font-roboto text-storm-key">
                                  ({stopLossList.length})
                                </span>
                              </span>
                            ),
                          }}
                          openDefault={true}
                        >
                          {stopLossList?.map((slItem, index) => {
                            return (
                              <SetupPointsPreview
                                label={`SL ${index + 1}`}
                                key={index}
                                value={slItem.amount}
                                weight={slItem.weight}
                                isClosed={slItem?.is_closed}
                              />
                            );
                          })}
                        </Accordion>
                      )}
                    </div>
                  </div>
                  {(permissions?.new_comment || permissions?.comment_list) && (
                    <Comment permissions={permissions} section="setup" id={id} />
                  )}
                </div>
                <RightNav>
                  <Accordion
                    className={{ root: "px-0" }}
                    label={{
                      leftPart: <span>State History</span>,
                    }}
                    openDefault={true}
                  >
                    <History isStatusChanged={isStatusChanged} id={id} />
                  </Accordion>
                  <Accordion
                    className={{ root: "px-0 border-b border-storm-30" }}
                    label={{
                      leftPart: <span>History</span>,
                    }}
                    openDefault={true}
                  >
                    <EditHistory
                      setActiveAnalysis={setActiveAnalysis}
                      activeAnalysis={activeAnalysis}
                      id={id}
                      historyDetails={historyDetails}
                      fetchEditHistory={(id) => fetchEditHistory(id)}
                      uuidPath={["deleted_setup", "uuid"]}
                    />
                  </Accordion>
                  <Accordion
                    className={{ root: "px-0" }}
                    label={{
                      leftPart: (
                        <span>
                          Private Note
                          {totalValuesOfPrivateNotes > 0 && (
                            <span className="total">({totalValuesOfPrivateNotes})</span>
                          )}
                        </span>
                      ),
                    }}
                    openDefault={true}
                  >
                    {(permissions?.new_private_note ||
                      permissions?.private_note_list) && (
                      <PrivateNote
                        permissions={permissions}
                        section="setup"
                        setTotalCount={setTotalValuesOfPrivateNotes}
                        id={id}
                      />
                    )}
                  </Accordion>
                </RightNav>
              </div>
              {showConfirmModal && (
                <ChangeStateConfirm
                  id={id}
                  setShowConfirmModal={setShowConfirmModal}
                  setIsStatusChanged={setIsStatusChanged}
                />
              )}
            </>
          ) : !isLoading ? (
            <div className="main__router__container">
              <div className="m-auto">
                <h4 className=" py-12 px-32 bg-base font-roboto rounded-xl">
                  There isn't any setup with this Id!
                </h4>
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </>
      ) : (
        permissions !== undefined && (
          <p className="my-[50%] mx-auto text-infrared-key">
            You don't have permission to add new analysis.
          </p>
        )
      )}
    </>
  );
};

export default View;
