import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import { TVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/fullChart/TVChartContainer";
import { Loading } from "~/components/child/ui/Loading";
import {useContext} from "react";
import {MainChartContext} from "~/components/child/tradingViewMainChart/context/MainChartContext";

const FullChart = () => {
  const {
    isLoading,
    chartData,
    setChartData,
    theme, 
    setTheme,
    favorites,
    setFavorites,  
    layoutList,
    setLayoutList,
    templateList, 
    setTemplateList,
    nearRealTimeData,
    setNearRealTimeData,
    isChartDataValid,
    hasFavoriteChartDataChanged
  } = useContext(MainChartContext);

  return (
    <div className={`${chartSize?.fullScreen}`}>
      { isChartDataValid ? (
        <TVChartContainer
          chartData={chartData}
          setChartData={setChartData}
          theme={theme}
          setTheme={setTheme}
          favorites={favorites}
          setFavorites={setFavorites}
          layoutList={layoutList}
          setLayoutList={setLayoutList}
          templateList={templateList}
          setTemplateList={setTemplateList}
          nearRealTimeData={nearRealTimeData}
          setNearRealTimeData={setNearRealTimeData}
          hasFavoriteChartDataChanged={hasFavoriteChartDataChanged}
        />
      ) : !isLoading ? (
        <DataFailureAlert />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default FullChart;
