import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import SettingSvg from "../../assets/icon/svgJsx/SettingSvg";
import ArrowRightSvg from "../../assets/icon/svgJsx/ArrowRightSvg";
import StrongBoxSvg from "../../assets/icon/svgJsx/StrongBoxSvg";
import ProfileSvg from "../../assets/icon/svgJsx/ProfileSvg";

export default function Settings({
  parentCategoryName,
  navBoxIsOpen,
  settingsIsOpen,
  setSettingsIsOpen,
  breadcrumbItems,
  user,
}) {
  const clickOnItems = () => {
    if (!navBoxIsOpen) {
      setSettingsIsOpen(false);
    }
  };

  const parentItemRef = useRef(null);
  const childListRefItem = useRef(null);
  const currentMainListOfNav = breadcrumbItems?.main === parentCategoryName;

  useEffect(() => {
    if (!navBoxIsOpen) {
      setSettingsIsOpen(false);
    }
    if (navBoxIsOpen && currentMainListOfNav) {
      setSettingsIsOpen(true);
    }
    const handleClickOutside = (event) => {
      if (!navBoxIsOpen) {
        if (
          parentItemRef.current &&
          childListRefItem.current &&
          !parentItemRef.current.contains(event.target) &&
          !childListRefItem.current.contains(event.target)
        ) {
          setSettingsIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [parentItemRef, childListRefItem, navBoxIsOpen]);

  return (
    <li
      className={`nav__list__item itemClose ${settingsIsOpen && "listOpen"} ${
        !navBoxIsOpen && currentMainListOfNav && "isActiveClose"
      }`}
    >
      <div
        ref={parentItemRef}
        onClick={() => setSettingsIsOpen(!settingsIsOpen)}
        className="nav__list__item__box"
      >
        <div className="nav__list__item__box__title">
          <i className="nav__list__item__box__icon">
            <SettingSvg />
          </i>
          <span>{navBoxIsOpen && <div> {parentCategoryName} </div>}</span>
        </div>

        <div>
          <i
            className={`nav__list__item__box__arrow-icon ${
              settingsIsOpen ? "arrow-rotate-left" : ""
            }`}
          >
            {/* arrow-right */}
            <ArrowRightSvg />
          </i>
        </div>
      </div>
      <div
        className={`nav__list__item__sub-category ${!navBoxIsOpen && "sub-ctgy-mode-2"}`}
      >
        <ul ref={childListRefItem}>
          {!navBoxIsOpen && (
            <span className="nav__list__item__sub-category__title">
              {parentCategoryName}
            </span>
          )}

          <NavLink
            onClick={clickOnItems}
            to="/newPair"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <li>
              <i>
                <StrongBoxSvg />
              </i>
              <span>New Pair</span>
            </li>
          </NavLink>
          <NavLink
            onClick={clickOnItems}
            to="/roles"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <li>
              <i>
                <ProfileSvg className="w-6 h-6" />
              </i>
              <span>Roles</span>
            </li>
          </NavLink>
        </ul>
      </div>
    </li>
  );
}
