import React from "react";

const InputBase = ({ title, value, error, setValue, children, className, ...props }) => {
  return (
    <div
      className={` relative border ${
        error ? "border-infrared-key" : "border-storm-20"
      } rounded-2xl font-bold font-roboto cursor-pointer ${className?.root}`}
    >
      {title && (
        <div
          className={` ${
            error ? "text-infrared-key" : "text-oil-60"
          } text-[0.625rem] absolute left-3 -top-2 px-0 bg-base ${className?.title}`}
        >
          {title}
        </div>
      )}

      <div className=" pr-3 pl-1 py-1 text-xs">
        {children ? (
          children
        ) : (
          <input
            className={`p-3 w-[97%] border-none focus:border-none focus-visible:border-none outline-none text-right font-yekanBakh text-xs text-oil-dark ${className?.input}`}
            value={value}
            onChange={setValue}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

export default InputBase;
