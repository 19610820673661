import React from "react";

export const AccessLevelTable = ({ accessLevels, setAccessLevels, editable = true }) => {
  const handleCheckboxChange = (sectionIndex, accessType) => {
    const updatedAccessLevels = accessLevels?.map((section, index) => {
      if (index === sectionIndex) {
        return {
          ...section,
          actions: section.actions.map((action) =>
            action.type === accessType
              ? { ...action, hasPermission: !action.hasPermission }
              : action
          ),
        };
      }
      return section;
    });
    setAccessLevels(updatedAccessLevels);
  };

  return (
    <table className="min-w-full border-separate rounded-[0.625rem] bg-white border-x border-t border-sky-20">
      <thead>
        <tr className="bg-sky-light">
          <th className="py-5 text-base border-r text-storm-80 font-robotoMed border-b border-sky-20">
            Sections
          </th>
          <th className="py-5 border-r text-base text-storm-80 font-robotoMed  border-b border-sky-20">
            Create
          </th>
          <th className="py-5 border-r text-base text-storm-80 font-robotoMed border-b border-sky-20">
            Edit
          </th>
          <th className="py-5 border-r text-base text-storm-80 font-robotoMed border-b border-sky-20">
            Delete
          </th>
          <th className="py-5 border-r text-base text-storm-80 font-robotoMed border-b border-sky-20">
            View
          </th>
          <th className="py-5 text-base text-storm-80 font-robotoMed border-b border-sky-20">
            View All
          </th>
        </tr>
      </thead>
      <tbody>
        {accessLevels?.map((section, index) => (
          <tr className="bg-sky-light" key={section.name}>
            <td className="py-5 text-center border-r border-b border-sky-20 bg-[#E5F4FF]">
              {section.name}
            </td>
            {section.actions.map((action, i) => (
              <td
                className={`py-5 ${
                  i !== 4 ? "border-r" : ""
                }  text-center border-b border-sky-20`}
                key={`${section.name}-${action.type}`}
              >
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={action.hasPermission}
                  onChange={() => handleCheckboxChange(index, action.type)}
                  className="form-checkbox disabled:cursor-not-allowed h-4 w-4 text-blue-600"
                  aria-label={`${section.name} ${action.type}`}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
