import React, {useEffect, useRef, useState} from "react";

import FullChartProcessorClass from "../../utils/classes/fullChartProcessorClass";
import PropTypes from "prop-types";

import { widget } from "~/charting_library";
import { createChartConfig } from "../../config/createChartConfig";
import { menuTvIcons } from "../../utils/helpers/tools/menuTvIcons";

import "../index.css";
import {
  createChartfavorites,
  createChartFavoritesSettingsAdapter
} from "~/components/child/tradingViewMainChart/utils/creators/createChartFavorites";
import {
  nearRealTimeDataUpdateHelper
} from "~/components/child/tradingViewMainChart/utils/helpers/api/nearRealTimeDataUpdateHelper";

export const TVChartContainer = ({
  chartData,
  setChartData,
  theme,
  setTheme,
  favorites,
  setFavorites,
  layoutList,
  setLayoutList,
  templateList,
  setTemplateList,
  nearRealTimeData,
  hasFavoriteChartDataChanged
}) => {
  const chartContainerRef = useRef();
  const currentNearRealTimeDataRef = useRef(null);
  const beforeNearRealTimeDataRef = useRef(null);
  const hasInitialized = useRef(false)

  const { baseChartConfig, specificConfigs } = createChartConfig("mainChart");
  const [chartHasChanged, setChartHasChanged] = useState(false);

  const hasUnsavedChanges = () => {
    return currentNearRealTimeDataRef.current &&
        beforeNearRealTimeDataRef.current &&
        beforeNearRealTimeDataRef.current !== currentNearRealTimeDataRef.current;
  }

  const hasFullChartDataChanged = () => {
    if (isNearRealTimeDataValid && hasUnsavedChanges()) {
      const newNearRealTimeData = JSON.parse(currentNearRealTimeDataRef.current)
      beforeNearRealTimeDataRef.current = currentNearRealTimeDataRef.current;
      nearRealTimeDataUpdateHelper(newNearRealTimeData)
    }
  }


  const isNearRealTimeDataValid = !!(
      nearRealTimeData &&
      nearRealTimeData?.chartData &&
      nearRealTimeData?.chartTheme &&
      nearRealTimeData?.favorites
  );


  useEffect(() => {
    if (!hasInitialized.current && nearRealTimeData) {
      const stringifyNearRealTimeData = JSON.stringify(nearRealTimeData);
      currentNearRealTimeDataRef.current = stringifyNearRealTimeData;
      beforeNearRealTimeDataRef.current = stringifyNearRealTimeData;
      hasInitialized.current = true;
    }
  }, [nearRealTimeData]);

  useEffect(() => {
    if (hasInitialized.current && nearRealTimeData) {
      currentNearRealTimeDataRef.current = JSON.stringify(nearRealTimeData);
    }
  }, [nearRealTimeData]);

  useEffect(() => {
    const beforeUnloadEvent = (event) => {
      if (hasUnsavedChanges()) {
        event.preventDefault();
        event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
        hasFullChartDataChanged();
      }
    };

    window.addEventListener("beforeunload", beforeUnloadEvent);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadEvent);
    };
  }, []);

  useEffect(() => {
    const widgetOptions = {
      ...baseChartConfig?.defaultProps,
      container: chartContainerRef.current,
      datafeed: baseChartConfig?.createDatafeed(baseChartConfig?.datafeedUrl),
      disabled_features: specificConfigs?.disabledFeatures,
      enabled_features: specificConfigs?.enabledFeatures,
      theme: theme,
      symbol: null,
      interval: "D",
      saved_data: chartData,

      save_load_adapter: {
        charts: layoutList,
        chartTemplates: templateList,
        saveLoadAdapterManager: new FullChartProcessorClass(
          nearRealTimeData,
          layoutList,
          templateList,
          setTemplateList,
          setLayoutList,
          setChartHasChanged
        ),

        getAllCharts: function () {
          return this.saveLoadAdapterManager.getAllCharts(layoutList);
        },
        saveChart: function (chartData) {
          return this.saveLoadAdapterManager.saveChart(chartData);
        },
        removeChart: function (id) {
          return this.saveLoadAdapterManager.removeChart(id);
        },
        getChartContent: function (id) {
          return this.saveLoadAdapterManager.getChartContent(id);
        },

        async getAllChartTemplates() {
          return this.saveLoadAdapterManager.getAllChartTemplates(templateList);
        },

        async saveChartTemplate(templateName, content) {
          return this.saveLoadAdapterManager.saveChartTemplate(templateName, content);
        },

        async removeChartTemplate(templateName) {
          templateList = templateList.filter((x) => x.name !== templateName);

          this.isDirty = true;
          return this.saveLoadAdapterManager.removeChartTemplate(templateList);
        },

        async getChartTemplateContent(templateName) {
          return this.saveLoadAdapterManager.getChartTemplateContent(templateName);
        },
      },

      context_menu: {
        items_processor: (defaultItems, actionFactory) => {
          const createMenuItem = (label, icon, theme) => {
            return actionFactory.createAction({
              label,
              icon,
              hint: `Reset theme to (${label} mode)`,
              onExecute: () => {
                setTheme(theme);
                tvWidget.changeTheme(theme);
                setChartHasChanged(true);
              },
            });
          };

          const darkModeItem = createMenuItem("Dark", menuTvIcons.darkMode, "dark");

          const lightModeItem = createMenuItem("Light", menuTvIcons.lightMode, "light");

          const items = [...defaultItems, darkModeItem, lightModeItem];
          return items;
        },
      },

      favorites: createChartfavorites(favorites),
      settings_adapter: createChartFavoritesSettingsAdapter(favorites, setFavorites),
    };
    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        tvWidget.save((data) => {
          setChartData(data);
        });
        tvWidget.subscribe("undo_redo_state_changed", () => {
          tvWidget.save((data) => {
            setChartHasChanged(true);
            setChartData(data);
          });
        });
      });
    });
    return () => {
      tvWidget.remove();
      hasFavoriteChartDataChanged(hasUnsavedChanges())
      hasFullChartDataChanged()
    };
  }, []);

  return (
    <>
      <div ref={chartContainerRef} className={"TVChartContainer"} />
    </>
  );
};

TVChartContainer.propTypes = {
  chartData: PropTypes.object.isRequired,
  setChartData: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  setFavorites: PropTypes.func.isRequired,
  layoutList: PropTypes.array.isRequired,
  setLayoutList: PropTypes.func.isRequired,
  templateList: PropTypes.array.isRequired,
  setTemplateList: PropTypes.func.isRequired,
  nearRealTimeData: PropTypes.object.isRequired,
  hasFavoriteChartDataChanged: PropTypes.func.isRequired
};
